@charset "UTF-8";
@use "sass:list";
@use "sass:math";
@use "sass:math";

:root {
  /* Colors */
  --color-hover2: #903FD7;
  --color-primary2: #75ff37;
  --color-secondary2: #7B46CD;
  --body-text2: #ffffff;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-fbfbfb: #fbfbfb;
  --color-a6a7ac: #a6a7ac;
  --color-999999: #999999;
  --color-262626: #262626;
  --color-010214: #010214;
  --color-e24c3e: #e24c3e;
  --color-854fcb: #854fcb;
}

body {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: var(--body-text2);
  background-color: #010314;
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

@media screen and (min-width: 992px) {
  body {
    font-size: 16px;
  }
}

#window {
  width: 100%;
  min-height: 100%;
  transition: transform 0.4s ease-in-out;
  -ms-transition: transform 0.4s ease-in-out;
  -o-transition: transform 0.4s ease-in-out;
  -webkit-transition: transform 0.4s ease-in-out;
  -moz-transition: transform 0.4s ease-in-out;
}

#window:after {
  content: "";
  display: table;
  clear: both;
}

.body-wrap {
  min-height: 300px; 
  width: 100%;
  margin: 0 auto;
}

.body-wrap:after {
  content: "";
  display: table;
  clear: both;
}

p {
  line-height: 1.85;
  margin-bottom: 20px;
}

@media screen and (min-width: 992px) {
  p {
    margin-bottom: 25px;
  }
}

a {
  text-decoration: none;
  color: var(--color-ffffff);
}

a:hover {
  text-decoration: none;
  color: var(--color-hover2);
}

hr {
  margin-top: 16px;
  margin-bottom: 16px;
  border: 0;
  opacity: 1;
  border-top: 1px solid #21222b;
}

img,
svg {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

figure {
  display: block;
  position: relative;
  overflow: hidden;
  margin: 0;
}

figure:after {
  content: "";
  display: table;
  clear: both;
}

a:focus,
:focus-visible {
  outline: none;
}

ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

ul li {
  padding: 0px;
  margin: 0px;
}

.none {
  display: none !important;
}

.hidescroll {
  overflow: hidden;
}

.container {
  max-width: 1624px;
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  margin: 0 auto;
  position: relative;
}

.container:after {
  content: "";
  display: table;
  clear: both;
}

.container.full {
  max-width: 100%;
  padding-left: 12px;
  padding-right: 12px;
}

.container.med {
  max-width: 1284px;
}

.gbl {
  margin-top: 50px;
  margin-bottom: 50px;
}

@media screen and (min-width: 992px) {
  .gbl {
    margin-top: 70px;
    margin-bottom: 70px;
  }
}

.form-control:disabled{color: #a3a3a3;}

.dashboard-wrap h1,
.dashboard-wrap h2,
.dashboard-wrap h3,
.dashboard-wrap h4,
.dashboard-wrap h5,
.dashboard-wrap h6 {
  color: var(--color-1e1e1f);
  font-weight: 400;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  position: relative;
  line-height: normal;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.dashboard-wrap h2 {
  font-size: 22px;
}

.dashboard-wrap h3 {
  font-size: 22px;
  font-weight: 600;
}

.dashboard-wrap h4 {
  font-size: 20px;
}
.dashboard-wrap h5 {
  font-size: 18px;
}
.dashboard-wrap h6 {
  font-size: 16px;
}

.dashboard-wrap .text-right {
  text-align: right;
}

.dashboard-wrap .text-left {
  text-align: left;
}

.dashboard-wrap .text-center {
  text-align: center;
}

.dashboard-wrap .text-14 {
  font-size: 14px;
}

.dashboard-wrap .text-12 {
  font-size: 12px;
}

.dashboard-wrap .text-10 {
  font-size: 10px;
}

.dashboard-wrap .text-8 {
  font-size: 8px;
}

.text-grey{color: rgba(255, 255, 255, 0.30);}
.text-grey2{color: var(--color-a6a7ac);}

.for-mobile {
  display: none;
}

@media screen and (max-width: 992px) {
  .for-desktop {
    display: none !important;
  }

  .for-mobile {
    display: block;
  }
}

img.cover,
video.cover {
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
}


*.mt--0 {
  margin-top: 0;
}

*.mt--10 {
  margin-top: 10px;
}

*.mt--20 {
  margin-top: 20px;
}

*.mt--30 {
  margin-top: 30px;
}

*.mt--40 {
  margin-top: 40px;
}

*.mt--50 {
  margin-top: 50px;
}

*.mt--60 {
  margin-top: 60px;
}

*.mt--70 {
  margin-top: 70px;
}

*.mt--80 {
  margin-top: 80px;
}

*.mt--90 {
  margin-top: 90px;
}

*.mt--100 {
  margin-top: 100px;
}

*.mb--0 {
  margin-bottom: 0 !important;
}

*.mb--10 {
  margin-bottom: 10px;
}

*.mb--20 {
  margin-bottom: 20px;
}

*.mb--30 {
  margin-bottom: 30px;
}

*.mb--40 {
  margin-bottom: 40px;
}

*.mb--50 {
  margin-bottom: 50px;
}

*.mb--60 {
  margin-bottom: 60px;
}

*.mb--70 {
  margin-bottom: 70px;
}

*.mb--80 {
  margin-bottom: 80px;
}

*.mb--90 {
  margin-bottom: 90px;
}

*.mb--100 {
  margin-bottom: 100px;
}

*.pt--0 {
  padding-top: 0;
}

*.pt--10 {
  padding-top: 10px;
}

*.pt--20 {
  padding-top: 20px;
}

*.pt--30 {
  padding-top: 30px;
}

*.pt--40 {
  padding-top: 40px;
}

*.pt--50 {
  padding-top: 50px;
}

*.pt--60 {
  padding-top: 60px;
}

*.pt--70 {
  padding-top: 70px;
}

*.pt--80 {
  padding-top: 80px;
}

*.pt--90 {
  padding-top: 90px;
}

*.pt--100 {
  padding-top: 100px;
}

*.pb--0 {
  padding-bottom: 0 !important;
}

*.pb--10 {
  padding-bottom: 10px;
}

*.pb--20 {
  padding-bottom: 20px;
}

*.pb--30 {
  padding-bottom: 30px;
}

*.pb--40 {
  padding-bottom: 40px;
}

*.pb--50 {
  padding-bottom: 50px;
}

*.pb--60 {
  padding-bottom: 60px;
}

*.pb--70 {
  padding-bottom: 70px;
}

*.pb--80 {
  padding-bottom: 80px;
}

*.pb--90 {
  padding-bottom: 90px;
}

*.pb--100 {
  padding-bottom: 100px;
}

.dashboard-wrap {
  display: flex;
}

.dashboard-wrap .dashboard-right {
  position: relative;
  flex: 1 1 auto;
  padding: 30px;
}

.dashboard-wrap .dashboard-right:before {
  content: '';
  position: absolute;
  left: 60%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 100px;
  z-index: -1;
  width: 240px;
  height: 240px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background-color: #230b3f;
  box-shadow: 0px 0px 100px 100px #230b3f;
}

.dashboard-wrap .dashboard-right .top-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  position: relative;
}

.dashboard-wrap .dashboard-right .top-head_left {
  -ms-flex: 370px;
  flex: 0 0 370px;
  max-width: 370px;
}

.dashboard-wrap .dashboard-right .top-head_left .page-search {
  position: relative;
}

.dashboard-wrap .dashboard-right .top-head_left .page-search .fa-magnifying-glass {
  position: absolute;
  left: 20px;
  position: absolute;
  top: 50%;
  z-index: 9;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.dashboard-wrap .dashboard-right .top-head_left .page-search .form-control {
  padding: 15px 10px 15px 50px;
  background-color: #111628;
  border: 1px solid #111628;
}

.dashboard-wrap .dashboard-right .top-head_right ul {
  display: flex;
  align-items: center;
  gap: 25px;
}

.dashboard-wrap .dashboard-right .top-head_right ul li.notification {
  position: relative;
  font-size: 26px;
}
.dashboard-wrap .dashboard-right .top-head_right ul li.notification spna{
  position: absolute; right: -6px; top: 0px;
  width: 22px; height: 22px; font-size: 12px;
  background: #f00; border-radius: 30px; 
  display: flex; align-items: center; justify-content: center;
}

.dashboard-wrap .dashboard-right .top-head_right ul li.login-user span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #ccc;
}

.dashboard-wrap .dashboard-right .top-head_right ul li.login-user span img,
.dashboard-wrap .dashboard-right .top-head_right ul li.login-user span svg {
  height: 100%;
  object-fit: cover;
}

.dashboard-wrap .dashboard-right .banner-home_text h1 {
  font-weight: 700; letter-spacing: 1px; text-transform: uppercase;
  background: linear-gradient(to right, #A1A3A8 0%, #FFFFFF 50%, #9B9BA2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--color-white);
}
.dashboard-wrap .dashboard-right .banner-home_text h1:after{bottom: 0;}

.dashboard-wrap .dashboard-right .banner-home_text h2 {
  line-height: 60px; font-weight: 700; text-transform: uppercase;
  background: linear-gradient(to right, #A1A3A8 0%, #FFFFFF 50%, #9B9BA2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--color-white);
}

.dashboard-wrap .dashboard-right .pricing-grid_list h3{font-size: 52px; line-height: 130%; margin-bottom: 20px; font-weight: 300;}

.dashboard-wrap .dashboard-feature {
  flex-wrap: wrap;
  /*gap: 15px;*/
  gap: 1%;
  margin-bottom: 20px;
}

.dashboard-wrap .dashboard-feature .cards {
  /*-ms-flex: 25%;
  flex: 0 0 25%;
  max-width: 25%;*/
  position: relative;
  margin-bottom: 20px;
  width: 24%;
}

.dashboard-wrap .dashboard-feature .cards:before {
  content: '';
  width: 34px;
  height: 5px;
  position: absolute;
  left: 23px;
  top: 12px;
  background-color: var(--color-primary2);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.dashboard-wrap .dashboard-feature .cards.total-chatbot:before {
  background-color: #FB8ADA;
}

.dashboard-wrap .dashboard-feature .cards.new-messages:before {
  background-color: #75ff36;
}

.dashboard-wrap .dashboard-feature .cards.total-activ-boat:before {
  background-color: #4EF3FE;
}

.dashboard-wrap .dashboard-feature .cards.all-message:before {
  background-color: #FF9067;
}

.dashboard-wrap .dashboard-feature .cards_feature {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 50px 15px 15px;
  display: flex;
  gap: 15px;
}

.dashboard-wrap .dashboard-feature .cards_feature figure {
  margin: 0;
  padding: 5px;
  height: 50px;
  -ms-flex: 50px;
  flex: 0 0 50px;
  max-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #27213d;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.dashboard-wrap .dashboard-feature .cards_feature aside h4 {  
  max-width: 180px;
  display: block;
  white-space: nowrap;  
  font-size: 20px;
  margin: 10px 0;
  /*overflow: hidden;
  text-overflow: ellipsis;
  min-height: 40px;
  line-height: 100%;*/
}

.dashboard-wrap .dashboard-feature .cards_feature aside span {
  font-size: 54px;
  font-weight: 100;
  line-height: 100%;
}

@media screen and (max-width: 1450px){
  .dashboard-wrap .dashboard-feature .cards:before{width: 24px; left: 20px;}
  .dashboard-wrap .dashboard-feature .cards_feature{padding-top: 35px;}
  .dashboard-wrap .dashboard-feature .cards_feature aside h4{font-size: 16px; margin-top: 5px; max-width: 120px;}
  .dashboard-wrap .dashboard-feature .cards_feature aside span {font-size: 44px;}
  .dashboard-wrap .dashboard-feature .cards_feature figure{height: 36px; -ms-flex: 36px; flex: 0 0 36px;  max-width: 36px;}  
  .dashboard-wrap .dashboard-feature .cards_feature figure img{max-width: 80%;}
  
}


.dashboard-wrap .dashboard-chart-grid {
  margin-bottom: 40px;
}

.dashboard-wrap .dashboard-chart-grid header {
  margin-bottom: 15px;
}

.dashboard-wrap .dashboard-chart-grid header h6 {
  margin-bottom: 0px;
}
.dashboard-wrap .dashboard-chart-grid .dropdown_select select {
  background-color: var(--color-primary2);
  color: #000;
}
.dashboard-wrap .dashboard-chart-grid .dropdown_select .form-select:focus{border: 0px !important;}
.dashboard-wrap .dashboard-chart-grid .dropdown_select select option {color: #fff;}
.dashboard-wrap .dashboard-chart-grid .dropdown_select select .delect-text {color: #ff0000;}
.dashboard-wrap .dashboard-chart-grid .dropdown_select select .inactive-text {color: rgba(255, 255, 255, 0.3);}

.dashboard-wrap .all-chatboat header {
  margin-bottom: 15px;
}

.dashboard-wrap .all-chatboat header h3 {
  margin-bottom: 0px;
}

.dashboard-wrap .all-chatboat header .totalEarning{font-size: 22px;}
.dashboard-wrap .all-chatboat header .totalEarning strong{font-weight: 600;}
.dashboard-wrap .with-invitation-link img{margin-left: 15px;}

.dashboard-wrap .mychatbot-grid header {
  margin-bottom: 30px;
}

.dashboard-wrap .mychatbot-grid_lists {
  position: relative;
  flex-wrap: wrap;
  gap: 3%;
}

.dashboard-wrap .mychatbot-grid_lists .chatbot-list {
  width: 31.333%;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 18px 0;
}

.dashboard-wrap .mychatbot-grid_lists .chatbot-list h5 {
  margin-top: auto;
  margin-bottom: 0px;
}


.tabs-grid{padding: 0px; position: relative;}
.tabs-grid .tab-lists{ border-bottom: 1px solid #D3D3D3; display: flex; gap: 45px; margin-bottom: 20px;}
.tabs-grid .tab-lists li{color: rgba(255, 255, 255, 0.45); position: relative;}
.tabs-grid .tab-lists li:after{ content: ''; position: absolute; left: 0; bottom: -3px; width: 100%; height: 5px;}
.tabs-grid .tab-lists li a{display: block; padding: 15px 0;}
.tabs-grid .tab-lists li.active:after{background-color: var(--color-854fcb);}
.tabs-grid .tab-lists li.active a{color: #ffffff;}
.tabs-grid .tab-lists li.inactive{pointer-events: none; opacity: 0.5;}

.tabs-grid .tab-content-grid{position: relative;}
.tabs-grid .tab-content-grid .tab-content.active{display: block;}

.appearance-grid{
  display: flex;
  gap: 14px;
}
.appearance-select-color{padding: 25px 20px;}
.appearance-select-color .form-select{width: 100%; background-color: #171735;}
.appearance-select-color .field {margin-bottom: 15px;}
.appearance-select-color .field label{font-size: 16px;}

.button-group .cta-border-w{
  border-radius: 10px; text-align: left;
  padding: 10px; margin-bottom: 12px;
}
.button-group .cta-border-w img{margin-right: 10px;}

.customize-messages .field{margin-bottom: 20px;}
.customize-messages .field .with-icon{position: relative;}
.customize-messages .field .form-control{background-color: transparent;}
.customize-messages .field .with-icon .form-control{padding: 10px 30px 10px 10px;}
.customize-messages .field .with-icon img{position: absolute; right: 10px; top: 9px;}

.dashboard-wrap .chatbot-history-grid header .group-form .cta-border-w{
  border-radius:10px; padding: 9px 10px;
  display: flex; align-items: center; justify-content: center;
}
.create-reorder-chatflow header{border-bottom:1px solid #2c2e3a;}

.chatflow-grid{margin: 20px 0; position: relative;}
.chatflow-grid ul li{margin: 15px 0;}
.chatflow-grid ul li.user-chatflow{display: flex; gap: 15px;}
.chatflow-grid ul li.user-chatflow figure{width: 40px; margin: 0;}
.chatflow-grid ul li.user-chatflow aside{
  padding: 10px; background-color: #fff; color: #000; font-size: 14px;
  border-radius: 30px; border-bottom-left-radius: 0; max-width: 300px;
  white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
}

.chatflow-grid ul li.user-chatflow-reply{display: flex; justify-content: flex-end;}
.chatflow-grid ul li.user-chatflow figure{width: 40px; margin: 0;}
.chatflow-grid ul li.user-chatflow-reply .chat-box{
  padding: 10px; background-color: #75ff36; color: #000; font-size: 14px;
  border-radius: 30px; border-bottom-right-radius: 0; max-width: 300px;
  white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
}

.chatflow-grid ul li.chatflow-option{display: flex; flex-wrap: wrap; gap: 10px; padding-left: 55px;}

.delete-item{padding: 10px; display: flex; margin-left: auto; width: 40px;}

.chatflow-option-grid{margin: 20px 0; border: 1px solid #fff; border-radius: 10px; padding: 0px 10px;}
.chatflow-option-grid ul li{margin: 15px 0;}
.chatflow-option-grid .chatflow-option{display: flex; flex-wrap: wrap; gap: 10px; padding-left: 55px;}
.chatflow-option-grid .chatflow-option .cta-border-w{border-color: #FB8ADA;}
.chatflow-option-grid .chatflow-option-copy{position: absolute; right: 35px; top:  -11px;}
.chatflow-option-grid .chatflow-option-remove{position: absolute; right: 10px; top: -11px;}

.generate-new-chat{margin: 20px 0; position: relative;}
.generate-new-chat img{z-index: 1; position: relative;}
.generate-new-chat:after{
  content: ''; position: absolute; left: 0; top: 50%; z-index: 0; 
  width: 100%; height: 1px; background-color: #2c2e3a;
}

.chatSettings-grid{position: relative; padding-top: 20px;}
.chatSettings-grid .field{margin-bottom: 30px;}
.chatSettings-grid .field label,
.chatSettings-grid .field select,
.chatSettings-grid .field textarea{font-size: 16px;}
.chatSettings-grid .field select.adjustBotPositon{
  background: url('../images/icons/select-arrow-img.png') no-repeat 96% 50%;
  -webkit-appearance: none !important;
      -moz-appearance: none !important;
          appearance: none !important;
}
.dashboard-wrap .chatSettings-grid .field select.adjustBotPositon option{background-color: #1F2645;}
.chatSettings-grid div.form-control{border-radius: 30px; color: var(--color-hover2);}
.chatSettings-grid .field .group-form .with-icon{position: relative; width: 400px;}
.chatSettings-grid .field .group-form .with-icon .fa-clone{position: absolute; right: 15px; top: 14px;}
.chatSettings-grid .field .group-form .with-icon .form-control{padding-right: 40px; color: var(--color-854fcb);}
.chatSettings-grid .field .group-form .with-icon .form-control:disabled {background-color: #000; opacity: 0.5;}

.chatSettings-grid .field .css-13cymwt-control,
.chatSettings-grid .field .css-t3ipsp-control{border: 1px solid #fff; background-color: #000; padding: 5px; border-radius: 10px;}
.chatSettings-grid .field .css-13cymwt-control{background-color: transparent;}
.chatSettings-grid .field .css-1p3m7a8-multiValue{background-color: #1F2645;} 
.chatSettings-grid .field .css-1p3m7a8-multiValue .css-9jq23d{color: #fff;}
.chatSettings-grid .field .css-1nmdiq5-menu{background-color: #1F2645;}
.chatSettings-grid .field .css-1dimb5e-singleValue{color: #fff;}


.chatSettings-grid .field .MuiOutlinedInput-notchedOutline{border: 1px solid #fff; border-radius: 10px;}
.chatSettings-grid .field .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{border-width: 1px; border-color: #fff;}
.chatSettings-grid .field .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {border-color: #fff;}
.chatSettings-grid .field input.MuiInputBase-input{padding: 10px 14px; color: #fff;}
.chatSettings-grid .field .MuiTypography-body1 {color: #fff;}

.chatSettings-grid .coupon-form-grid .custom-select__control{background-color: #010214; border-radius: 10px; padding: 3px;}
.chatSettings-grid .coupon-form-grid .css-bx9l3l-control:hover {border-color: #fff;}
.chatSettings-grid .coupon-form-grid .css-v7duua:hover {background-color: #4c2f66; color: #ffffff;} 

.chatSettings-grid .field .adjust-chatbot-height,
.chatSettings-grid .field .adjust-chatbot-width{position: relative;}
.chatSettings-grid .field .adjust-chatbot-height label,
.chatSettings-grid .field .adjust-chatbot-width label{
  position: absolute; left: 10px; top: -9px; z-index: 1;
  font-size: 12px; background: #000; width: auto; margin: 0; padding: 0 3px;
}
.chatSettings-grid .field .adjust-chatbot-height .form-control,
.chatSettings-grid .field .adjust-chatbot-width .form-control{padding: 12px;}
.chatSettings-grid .field .adjust-chatbot-height.readable,
.chatSettings-grid .field .adjust-chatbot-width.readable{opacity: 0.5;}


.dashboard-wrap .chatbot-history-grid .MuiStack-root{width: 260px; margin-top: -6px;}
.dashboard-wrap .chatbot-history-grid .MuiFormControl-root .MuiFormLabel-root{color: #fff;}
.dashboard-wrap .chatbot-history-grid .MuiFormControl-root input.MuiInputBase-input{padding: 10px 14px; color: #fff;}
.dashboard-wrap .chatbot-history-grid .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{border: 1px solid #fff;}
.dashboard-wrap .chatbot-history-grid .MuiOutlinedInput-notchedOutline{border: 1px solid #fff;  border-radius: 10px;}
.dashboard-wrap .chatbot-history-grid .MuiFormControl-root:hover .MuiOutlinedInput-notchedOutline {border-color: #fff;}
.dashboard-wrap .chatbot-history-grid .MuiIconButton-root{color: #fff; margin-right: 0px;}

.dashboard-wrap .chatbot-history-grid .MuiFormControl-root.dateAbc .MuiFormLabel-root,
.dashboard-wrap .chatbot-history-grid .MuiFormControl-root.timeAbc .MuiFormLabel-root{
  transform: translate(14px, 10px) scale(1);
}
.dashboard-wrap .chatbot-history-grid .MuiFormControl-root.dateAbc .MuiFormLabel-root.MuiFormLabel-filled,
.dashboard-wrap .chatbot-history-grid .MuiFormControl-root.timeAbc .MuiFormLabel-root.MuiFormLabel-filled,
.dashboard-wrap .chatbot-history-grid .MuiFormControl-root.dateAbc .MuiFormLabel-root.Mui-focused,
.dashboard-wrap .chatbot-history-grid .MuiFormControl-root.timeAbc .MuiFormLabel-root.Mui-focused{
  transform: translate(14px, -9px) scale(0.75);
}


.file-upload-container {
  display: flex; align-items: center; gap: 20px;
  padding: 15px 30px; border-radius: 10px; min-height: 80px;
  -webkit-border-radius: 10px; -moz-border-radius: 10px;  
  border: 1px dashed var(--color-white);
}
.file-upload-container.disabled{opacity: 0.4; pointer-events: none;}

.file-upload-container .file-upload-input {display: none;}
.file-upload-container .file-upload-label {margin: 0;}
.file-upload-container .image-preview {max-width: 80px; height: 50px; display: flex; align-items: center;}
.file-upload-container .image-preview img {max-height: 50px; object-fit: fill;}

.file-upload-container .uploadWidget{
  display: flex; align-items: center; gap: 20px;
  flex-direction: row-reverse; position: relative;
}
.file-upload-container .uploadWidget .file-upload-input{
  display: block; position: absolute; left: 0; top: 0;
  height: 40px; width: 40px; opacity: 0;
}
.file-upload-container .uploadWidget img{width: 40px;}

.subscription-plan-info{position: relative; margin-bottom:30px;}
.subscription-plan-info ul li{
  display: flex; align-items: center; justify-content: space-between; 
  padding: 20px 0; border-bottom:1px solid rgba(255, 255, 255, 0.3)
}
.subscription-plan-info .annual-agency-plan .subscription-plan_right{width: 410px; display: flex; align-items: center; gap: 20px;}
.subscription-plan-info .annual-agency-plan .subscription-plan_right .cta-green{width: 125px;}
.subscription-plan-info .cancel-subscription .subscription-plan_left{font-size: 16px;  color: rgba(255, 255, 255, 0.5);}


/* Wrapper for the scrollable table */
.table-responsive-verticle {width: 100%; overflow-x: auto;}
.table-responsive-verticle .table{border-spacing: 0 5px;}
.table-responsive-verticle table {width: 100%; border-collapse: collapse;}
.table-responsive-verticle thead {display: table; width: 100%; table-layout: fixed;}
.table-responsive-verticle tbody {display: block; max-height: 600px; overflow-y: auto; width: 100%; table-layout: fixed;}
.table-responsive-verticle tbody tr {display: table; width: 100%; table-layout: fixed;}

/* Custom scrollbar styling */
.table-responsive-verticle tbody { scrollbar-width: thin; scrollbar-color: var(--color-854fcb) #2c2e3b;}
.table-responsive-verticle tbody::-webkit-scrollbar {width: 8px;}
.table-responsive-verticle tbody::-webkit-scrollbar-track {background: #2c2e3b;}
.table-responsive-verticle tbody::-webkit-scrollbar-thumb {background-color: var(--color-854fcb); border-radius: 10px;}
.table-responsive-verticle tbody::-webkit-scrollbar-thumb:hover {background-color: #cb4f4f;}
/* Alternating row colors */

.verticle_scroll{max-height: 600px; overflow-x: auto;}
.verticle_scroll { scrollbar-width: thin; scrollbar-color: var(--color-854fcb) #2c2e3b;}
.verticle_scroll::-webkit-scrollbar {width: 8px;}
.verticle_scroll::-webkit-scrollbar-track {background: #2c2e3b;}
.verticle_scroll::-webkit-scrollbar-thumb {background-color: var(--color-854fcb); border-radius: 10px;}
.verticle_scroll::-webkit-scrollbar-thumb:hover {background-color: #cb4f4f;}

.subscription-plan-info .subscription-plan_left{
  font-weight: 300; font-size:20px;
}
.subscription-plan-info .subscription-plan_right{
  text-align: left; width: 260px;
  font-weight: 300; font-size:20px;
}

/* modal Overlay */
.modalOverlay{
  background-color: rgba(16, 21, 45, 0.5);
  backdrop-filter: blur(3px);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  opacity: 0;
  transition: opacity .15s linear;    
  display: none;
  overflow: hidden;
}
.modal-container {
  transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  /*-webkit-transform: translate(0, -100%);
  transform: translate(0, -100%);*/
  width: 100%;
  margin: auto;
  opacity: 0;
  border-radius: 40px;
  padding: 30px;
  min-height: 200px;
}
.modalOverlay.modal-open {
  overflow-x: hidden;
  overflow-y: auto;
  opacity: 1;
  display: block;
  padding: 50px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modalOverlay.modal-open .modal-container{
  /*-webkit-transform: translate(0, 0);
  transform: translate(0, 0);*/
  opacity: 1;
  position: relative;
}

.modalClose{
  position: absolute; cursor: pointer;
  right: 20px; top: 25px; z-index: 9;
}

.width-small{max-width: 340px;}
.width-sm{max-width: 500px;}
.width-md{max-width: 767px;}
.width-lg{max-width: 1024px;}
.modal-dark-bg{background-color: #09071d;}

.modal-form-grid h3{font-size: 24px;}
.modal-form-grid .cta-green{max-width: 226px; width: 100%;}
.modal-form-grid .file-upload-container{justify-content: center; border-radius: 20px; padding-top: 20px;}
.modal-form-grid .file-upload-container label{line-height: 24px;}
.modal-form-grid .file-upload-container label img{max-width: 100px;}
.modal-form-grid .file-upload-container .file-upload-label2{font-size: 12px; margin-bottom: 0px;}
.modal-form-grid .file-upload-container .file-upload-label2 span{color: #FB8ADA;}
.modal-form-grid .file-upload-container small{font-size:10px; color: rgba(255, 255, 255, 0.5);}

.document-list-grid{margin-bottom: 20px;}
.document-list-grid ul{display: flex; flex-wrap: wrap; gap: 20px;}
.document-list-grid ul li figure{position: relative; width: 50px; margin: 0 auto 6px; overflow: inherit;}
.document-list-grid ul li figure .remove-file{position: absolute; top: -8px; right: -8px;}
.document-list-grid ul li span{font-size: 12px;}
.document-list-grid  ul li img{width: 40px;}
.chatbot-history-grid


/* modal Overlay */



.box-24{width: 24%;}
.box-31{width: 31%;}
.box-38{width: 38%;}
.box-45{width: 45%;}
.box-50{width: 45%;}
.box-full{width: 100%;}


.dashboard-wrap .chatbot-history-grid {
  margin-bottom: 20px;
}

.dashboard-wrap .chatbot-history-grid header {
  margin-bottom: 15px;
}

.dashboard-wrap .chatbot-history-grid header h3 {
  margin-bottom: 0px;
}

.dashboard-wrap .all-chatboat header .group-form,
.dashboard-wrap .chatbot-history-grid header .group-form {
  gap: 12px; align-items: center;
}

.dashboard-wrap .chatbot-history-grid header .group-form .form-control {
  /*width: 150px;*/
  font-size: 14px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

.dashboard-wrap .chatbot-history-grid header .group-form .form-control:focus {
  color: var(--color-white);
  background-color: var(--color-010214);
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0);
}

.dashboard-wrap .chatbot-history-grid .table-responsive .table tr td.message {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboard-wrap .chatbot-history-grid .table-responsive .table .docfile-icon{width: 40px; color: #fff; fill: #fff;}

/* Chatbot History */
.dashboard-wrap .chatbot-history-wrap{height: 100%; max-height: 600px; overflow-y: auto;}

/* Chatbot History - User Chat */
.dashboard-wrap .chatbot-history-wrap ul li.userChat-wrap{margin: 15px 0; display: flex; align-items: self-start; gap: 20px;}
.dashboard-wrap .chatbot-history-wrap ul li.userChat-wrap article{padding-right: 10%; flex: 1 1 auto;}
.dashboard-wrap .chatbot-history-wrap ul li.userChat-wrap figure{
  margin: 0; background-color: var(--color-hover2); display: flex; align-items: center; justify-content: center;
  min-width: 36px; height: 36px; border-radius: 100%; padding: 8px;
} 
.dashboard-wrap .chatbot-history-wrap ul li.userChat-wrap .userChatText{
  max-width: 600px; border-radius: 10px; color: var(--color-hover2); font-size: 12px;
  border: 1px solid var(--color-hover2); padding: 12px; background-color: #fff;
}

.dashboard-wrap .chatbot-history-wrap ul li .chatflow-option{
  display: flex; align-items: center; gap: 5px; flex-wrap: wrap; margin-top: 5px;
}
.dashboard-wrap .chatbot-history-wrap ul li .chatflow-option span{
  background: var(--color-hover2); min-width: 50px; color: #fff; font-size: 11px; 
  padding: 4px 8px; border-radius: 5px; text-align: center;
}

.dashboard-wrap .chatbot-history-wrap ul li.userChat-wrap .chatTime{font-size: 10px; margin-top: 5px; margin-left: 12px;}

/* Chatbot History - Client Chat */
.dashboard-wrap .chatbot-history-wrap ul li.clientChat-wrap{margin: 15px 0; display: flex; align-items: self-start; gap: 20px; flex-direction: row-reverse;}
.dashboard-wrap .chatbot-history-wrap ul li.clientChat-wrap article{padding-left: 15%; flex: 1 1 auto;}
.dashboard-wrap .chatbot-history-wrap ul li.clientChat-wrap figure{
  display: flex; align-items: center; justify-content: center; margin: 0; 
  background-color: var(--color-hover2);  min-width: 36px; height: 36px; border-radius: 100%;
} 
.dashboard-wrap .chatbot-history-wrap ul li.clientChat-wrap figure img{height: 100%; object-fit: cover;}
.dashboard-wrap .chatbot-history-wrap ul li.clientChat-wrap .clientChatText{
  border-radius: 10px; color: #fff; font-size: 12px;
  padding: 12px; background-color: var(--color-hover2); text-align: right;
}
.dashboard-wrap .chatbot-history-wrap ul li.clientChat-wrap .chatTime{
  font-size: 10px; margin-top: 5px; display: flex; justify-content: end; margin-right: 12px;
}
/* END */

/* Custom scrollbar styling */
.dashboard-wrap .chatbot-history-wrap { scrollbar-width: thin; scrollbar-color: var(--color-854fcb) #2c2e3b;}
.dashboard-wrap .chatbot-history-wrap::-webkit-scrollbar {width: 8px;}
.dashboard-wrap .chatbot-history-wrap::-webkit-scrollbar-track {background: #2c2e3b;}
.dashboard-wrap .chatbot-history-wrap::-webkit-scrollbar-thumb {background-color: var(--color-854fcb); border-radius: 10px;}
.dashboard-wrap .chatbot-history-wrap::-webkit-scrollbar-thumb:hover {background-color: #cb4f4f;}
/* Alternating row colors */

.dashboard-wrap .my-profile-grid {
  position: relative;
}

.dashboard-wrap .my-profile-grid header {margin-bottom: 40px;}
.dashboard-wrap .my-profile-grid header .group-form {gap: 12px; align-items: center;}

.dashboard-wrap .my-profile-grid .myprofile-block {
  position: relative;
}

.dashboard-wrap .my-profile-grid .myprofile-block ul li {
  display: flex;
  align-items: center;
  gap: 60px;
  padding: 30px 0;
  border-bottom: 1px solid #3e3e3e;
}

.dashboard-wrap .my-profile-grid .myprofile-block ul li .info_left {
  -ms-flex: 260px;
  flex: 0 0 260px;
  max-width: 260px;
}

.dashboard-wrap .my-profile-grid .myprofile-block ul li .info_left p {
  margin-bottom: 0;
}

.dashboard-wrap .my-profile-grid .myprofile-block ul li .info_left span {
  font-size: 14px;
  color: var(--color-a6a7ac);
}

.dashboard-wrap .my-profile-grid .myprofile-block ul li .info_right{width: 100%;}
.dashboard-wrap .my-profile-grid .myprofile-block ul li .info_right input:disabled{
  font-weight: 600; border: 0px; width: 100%; 
  background: transparent;
}
.dashboard-wrap .my-profile-grid .myprofile-block ul li .info_right input{
  color: #fff; padding: 5px; font-weight: 600;
  background: #1F2645; border: 0px; flex: 0 0 100%;
}
.dashboard-wrap .my-profile-grid .myprofile-block ul li .info_right input[type="tel"]{
  color: #fff; padding: 5px; font-weight: 600; background: transparent; border: 0px;
  -webkit-text-fill-color: #fff; 
}
.dashboard-wrap .edit-text-block .MuiFormControl-root{flex: 0 0 88%;}

.dashboard-wrap .my-profile-grid .myprofile-block ul li .MuiInputAdornment-root{color: #fff;}
.dashboard-wrap .my-profile-grid .myprofile-block ul li .css-ot336b-MuiInputBase-root-MuiOutlinedInput-root{padding-left: 0px;}
.dashboard-wrap .my-profile-grid .myprofile-block ul li .css-1ll44ll-MuiOutlinedInput-notchedOutline{padding: 0;}
.dashboard-wrap .my-profile-grid .myprofile-block ul li .css-1bfa977-MuiButtonBase-root-MuiIconButton-root{padding-left: 0;}

.dashboard-wrap .my-profile-grid .myprofile-block ul li .profile-photo {position: relative; width: 60px;}

.dashboard-wrap .my-profile-grid .myprofile-block ul li .profile-photo .file-upload-grid{
  background-color: rgba(255, 255, 255, 0.8); position: relative;
  width: 36px; height: 36px; border-radius: 36px; 
  display: flex; align-items: center; justify-content: center;
  position: absolute; bottom: -6px; right: -6px;
}
.dashboard-wrap .my-profile-grid .myprofile-block ul li .profile-photo .file-upload-grid input{position: absolute; left: 0; top: 0; opacity: 0;}
.dashboard-wrap .my-profile-grid .myprofile-block ul li .profile-photo .file-upload-grid img{width: 20px;}
.dashboard-wrap .my-profile-grid .myprofile-block ul li .profile-photo .file-upload-grid .errMsg{
  position: absolute; left: 60px; top: -20px;
  font-size: 14px; width: 200px;
}

.dashboard-wrap .my-profile-grid .myprofile-block ul li .profile-photo figure {
  width: 60px; height: 60px; -webkit-border-radius: 50%; -moz-border-radius: 50%;
  border-radius: 50%; border: 1px solid #3e3e3e;
}
.dashboard-wrap .my-profile-grid .myprofile-block ul li .profile-photo figure img{
  height: 60px; width: 100%; object-fit: cover;
}
.dashboard-wrap .my-profile-grid .myprofile-block ul li .profile-photo .group-link {
  display: flex; align-items: flex-end; gap: 20px; padding-bottom: 10px;
}
.dashboard-wrap .my-profile-grid .myprofile-block ul li .edit-text-block {
  display: flex; align-items: center; justify-content: space-between; padding-right: 160px;
}
.dashboard-wrap .my-profile-grid .myprofile-block ul li .verify-phonenumber_block .edit-text-block{padding-right: 230px;} 
.dashboard-wrap .my-profile-grid .myprofile-block ul li .verify-phonenumber_block .MuiFormControl-root{flex: 0 0 100%;}

.dashboard-wrap .errMsg{flex-basis: 100%; display: block; font-size: 14px;}

.dashboard-wrap .my-profile-grid .myprofile-block ul li .edit-text-block h6 {margin-bottom: 0; font-weight: 600;}
.dashboard-wrap .my-profile-grid .myprofile-block .cta-green {font-size: 16px; font-weight: 600; padding: 20px 30px;}
.dashboard-wrap .my-profile-grid .myprofile-block .cta-green.med{padding: 12px 20px; font-weight: 400; font-size: 14px;}
.dashboard-wrap .my-profile-grid .myprofile-block .cta-green.small{padding: 10px 20px; font-weight: 400; font-size: 14px;}
.dashboard-wrap .my-profile-grid .myprofile-block .verified-tag{padding: 7px 14px; font-size: 12px; position: absolute; right: 45px; font-weight: 400;}
.dashboard-wrap .my-profile-grid .myprofile-block .text-link_purple{position: absolute; right: 0px;}

.dashboard-wrap .setting-grid header {margin-bottom: 30px;}
.dashboard-wrap .setting-grid .setting-block .chatbot-list ul li {display: flex; padding: 8px 0;}
.dashboard-wrap .setting-grid .setting-block .chatbot-list ul li .name-label {width: 140px; opacity: 0.6;}
.dashboard-wrap .setting-grid .setting-block .change-pass_info {max-width: 470px; width: 100%;}
.dashboard-wrap .setting-grid .setting-block .change-pass_form {max-width: 415px; width: 100%;}
.dashboard-wrap .setting-grid .setting-block .change-pass .text-12 {opacity: 0.6;}
.dashboard-wrap .setting-grid .setting-block .change-pass .form-control {background-color: #010214;}

.dashboard-wrap .chatbot-analytics-grid header {margin-bottom: 40px;}
.dashboard-wrap .chatbot-analytics-grid .chat-summary-block{display: flex; flex-wrap: wrap; gap: 20px 1.5%;}
.dashboard-wrap .chatbot-analytics-grid .chart-block .chart-grid_card {
  background-color: #171821; padding: 10px 20px; border-radius: 10px; width: 23.8%;
}

.dashboard-wrap .chatbot-analytics-grid .chart-block .chart-grid.column-5{gap: 20px 1.4%;}
.dashboard-wrap .chatbot-analytics-grid .chart-block .chart-grid.column-5 .chart-grid_card{width: 18.8%;}

.dashboard-wrap .chatbot-analytics-grid .chart-block .chart-grid.column-6{gap: 20px 1.4%;}
.dashboard-wrap .chatbot-analytics-grid .chart-block .chart-grid.column-6 .chart-grid_card{width: 15.45%;}

/*.dashboard-wrap .chatbot-analytics-grid .chart-block .chart-grid.column-4 .chart-grid_card{width: 25%;}
.dashboard-wrap .chatbot-analytics-grid .chart-block .chart-grid.column-5 .chart-grid_card{width: 20%;}
.dashboard-wrap .chatbot-analytics-grid .chart-block .chart-grid.column-6 .chart-grid_card{width: 16.66666667%;}*/




.dashboard-wrap .chatbot-analytics-grid .chart-block .chart-grid_card figure {margin-bottom: 15px;}
.dashboard-wrap .chatbot-analytics-grid .chart-block .chart-grid_card h6 {margin-bottom: 5px; font-weight: 600;}
.dashboard-wrap .chatbot-analytics-grid .chart-block .chart-grid_card p {margin-bottom: 0; line-height: 100%;}

.dashboard-wrap .chatbot-analytics-grid .chart-block .chart-grid .analytics-total-chatbot span {color: #FF9067;}
.dashboard-wrap .chatbot-analytics-grid .chart-block .chart-grid .analytics-active span {color: #FEB95A;}
.dashboard-wrap .chatbot-analytics-grid .chart-block .chart-grid .analytics-incoming span {color: #A9DFD8;}
.dashboard-wrap .chatbot-analytics-grid .chart-block .chart-grid .analytics-reply span {color: #F2C8ED;}
.dashboard-wrap .chatbot-analytics-grid .chart-block .chart-grid .analytics-user span {color: #20AEF3;}
.dashboard-wrap .chatbot-analytics-grid .chart-block .chart-grid .analytics-new-user span {color: #E24C3F;}


.dashboard-wrap .chart-container {display: flex; gap: 15px;}
.dashboard-wrap .chart-container .chart-block {
  flex: 1 1 auto; padding: 15px; margin-bottom: 15px;
  -webkit-border-radius: 10px; -moz-border-radius: 10px; border-radius: 10px;
}

.dashboard-wrap .chart-container .chart-block header {position: relative; margin-bottom: 20px;}
.dashboard-wrap .chart-container .chart-block header h6 {margin-bottom: 0;}
.dashboard-wrap .chart-container .chart-block header span {font-size: 10px; color: #87888C;}
.dashboard-wrap .chart-container .chart-block header.with-selectbox .form-select {
  position: absolute; right: 0; top: 0; width: 140px;
  font-size: 12px; padding: 5px 15px; background-color: #21222D;
}
.dashboard-wrap .chart-container .chart-block header.with-selectbox .form-select.with-dateSelect{padding: 0px; width: 100px;}
.dashboard-wrap .chart-container .chart-block header.with-selectbox .form-select.with-dateSelect > button{
  cursor: pointer; width: 100%; background: transparent;
  border: 1px; height: 100%; padding: 6px 15px; text-align: left;
}

.dashboard-wrap .chart-container .block-250 {-ms-flex: 250px; flex: 0 0 250px; max-width: 250px;}
.dashboard-wrap .chart-container .block-300 {-ms-flex: 300px; flex: 0 0 300px; max-width: 300px;}
.dashboard-wrap .chart-container .block-350 {-ms-flex: 350px; flex: 0 0 350px; max-width: 350px;}

.dashboard-wrap .dark-bg {background-color: #21222D;}
.dashboard-wrap .dark-bg2{background-color: #10152d; border-radius: 10px;}

.dashboard-wrap .border-box {
  border: 1px solid var(--color-white);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  transition: all 0.4s ease;
  color: var(--color-white);
  background-image: linear-gradient(#0e0c15, #0e0c15), linear-gradient(0deg, #FFE290, #9F6DAB, #854FCB);
  border-color: transparent;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

.dashboard-wrap .border-box-grey{
  border:1px solid #303030;
  border-radius: 10px;
  padding: 10px;
}
.remove-box{padding-bottom: 10px; display: flex; justify-content: flex-end;}

.dashboard-wrap .table-responsive .table {
  background-color: transparent;
  --bs-table-bg: $trans;
  --bs-table-color: var(--color-white);
  border-collapse: separate;
  border-spacing: 0 10px;
  margin-bottom: 0px;
}

.dashboard-wrap .table-responsive .table th,
.dashboard-wrap .table-responsive .table td {
  border-bottom-width: 0px;
  padding: 12px;
}

.dashboard-wrap .table-responsive .table th:first-child,
.dashboard-wrap .table-responsive .table td:first-child {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.dashboard-wrap .table-responsive .table th:last-child,
.dashboard-wrap .table-responsive .table td:last-child {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.dashboard-wrap .table-responsive .table thead tr th {
  background-color: var(--color-secondary2);
  font-weight: 400;
  color: var(--color-white);
}

.dashboard-wrap .table-responsive .table tbody tr td {
  background-color: #161a30;
  font-size: 14px;
}

.dashboard-wrap .table-responsive .table tbody tr td.action {
  width: 120px;
}

.dashboard-wrap .table-responsive .table tbody tr td.action a {
  display: inline-block;
  min-width: 20px;
}

.dashboard-wrap .table-responsive .table tbody tr td.action img {
  margin: 0 10px;
}

.dashboard-wrap .table-responsive .table tbody tr td.with-icon i{
  font-size: 18px; margin-right:10px;
}
.dashboard-wrap .table-responsive .table tbody tr td.with-link a{
  text-decoration: underline;
}
.dashboard-wrap .table-responsive .table tbody tr td.with-link a:hover{
  text-decoration: none;
}
.dashboard-wrap .table-responsive .table tbody tr td.with-link img{
  font-size: 18px; margin-right:10px;
}
.dashboard-wrap .table-responsive .table tbody tr td.with-user-img img{
  max-width: 30px; margin-right: 10px;
}

.dashboard-wrap .table-responsive .table tbody tr td.page-url{color: #75FF36;}
.dashboard-wrap .table-responsive .table tbody tr td.page-url span,
.dashboard-wrap .table-responsive .table tbody tr td.page-url a{
  color: #75FF36; max-width: 200px; display: block;
  white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
}
.dashboard-wrap .table-responsive .table tbody tr td .cta-green,
.dashboard-wrap .table-responsive .table tbody tr td .cta-red{padding: 10px; text-transform: none; font-size: 13px;}

.dashboard-wrap .table-responsive .table tbody tr td span.short-text{ 
  width:300px; margin: auto; overflow: hidden;
  text-overflow: ellipsis; display: -webkit-box;
  -webkit-line-clamp: 1; -webkit-box-orient: vertical
}

.dashboard-wrap .activeBotsTable{width: 100%;}
.dashboard-wrap .activeBotsTable td, 
.dashboard-wrap .activeBotsTable th {
  vertical-align: top; border-top: 1px solid #3b3b3b; 
  font-size: 12px;  font-weight: 400; padding: 8px; 
}
.dashboard-wrap .activeBotsTable thead th{color: #959595; font-size: 14px;}
.dashboard-wrap .activeBotsTable thead th:first-child{width: 80px;}

.dashboard-wrap .activeBotsTable tbody td{vertical-align: middle;}
.dashboard-wrap .MuiLinearProgress-root{background-color: #424242 !important;}
.dashboard-wrap .user-engagement-chart h5{font-size: 24px; font-weight: bold; color: var(--color-854fcb); margin-bottom: 0;}
.dashboard-wrap .user-engagement-chart p{font-size: 14px; color: #959595; margin-bottom: 15px;}


.dashboard-left {
  -ms-flex: 300px;
  flex: 0 0 300px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  background: #030a28;
  position: relative;
}

.dashboard-left .logo {
  padding: 20px 0 20px 40px;
  overflow: visible;
}

.dashboard-left .logo img {
  max-width: 150px;
}

.dashboard-left header {
  background-color: #1F2645;
  padding: 10px 5px 10px 40px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.dashboard-left header figure {
  width: 76px;
  height: 76px;
  padding: 4px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: var(--color-black);
  border: 2px solid #fb8ada;
}

.dashboard-left header figure img {
  object-fit: cover;
  width: 100px;
  height: 100%;
  border-radius: 50%;
}

.dashboard-left header aside h6 {
  margin-bottom: 0px;
  font-weight: 600;
}

.dashboard-left header small {
  font-size: 12px;
  line-height: 14px;
  color: var(--color-a6a7ac);
  word-break: break-word;
}
.dashboard-left header small a{line-height: 16px; display: block;}

.dashboard-left menu,
.dashboard-left footer {
  padding: 35px 0;
  margin: 0;
}

.dashboard-left menu h2,
.dashboard-left footer h2 {
  margin: 0 0 15px 50px;
  font-weight: 600;
  text-transform: uppercase;
}

.dashboard-left menu ul li,
.dashboard-left footer ul li {
  position: relative;
}

.dashboard-left menu ul li:before,
.dashboard-left footer ul li:before {
  content: '';
  width: 24px;
  height: 54px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0px;
}

.dashboard-left menu ul li a,
.dashboard-left footer ul li a {
  display: flex;
  align-items: center;
  position: relative;
  padding: 25px 10px 25px 50px;
}
.dashboard-left menu ul li a:hover,
.dashboard-left footer ul li a:hover {
  color: var(--color-hover2);
}

.dashboard-left menu ul li a:before,
.dashboard-left footer ul li a:before {
  content: '';
  width: 24px;
  height: 54px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0px;
}

.dashboard-left menu ul li a img+img,
.dashboard-left footer ul li a img+img {
  display: none;
}

.dashboard-left menu ul li a img,
.dashboard-left footer ul li a img,
.dashboard-left menu ul li a svg,
.dashboard-left footer ul li a svg {
  margin-right: 15px;
  max-width: 26px;
}

.dashboard-left menu ul li a:hover svg path,
.dashboard-left footer ul li a:hover svg path {
  fill: var(--color-secondary2);
}

.dashboard-left menu ul li a:hover img,
.dashboard-left footer ul li a:hover img {
  display: none;
}

.dashboard-left menu ul li a:hover img+img,
.dashboard-left footer ul li a:hover img+img {
  display: block;
}

.dashboard-left menu ul li a:hover:before,
.dashboard-left footer ul li a:hover:before {
  /*background-image: 'url(images/menu-arrow-left.png)';*/
  width: 0; height: 0;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  border-left: 16px solid var(--color-hover2);
}

.dashboard-left menu ul li.active a,
.dashboard-left footer ul li.active a {
  color: var(--color-hover2);
}

.dashboard-left menu ul li.active a:before,
.dashboard-left footer ul li.active a:before {
  /*background-image: 'url(images/menu-arrow-left.png)';*/
  width: 0; height: 0;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  border-left: 16px solid var(--color-hover2);
  border-bottom-right-radius: 10px;
}

.dashboard-left menu ul li.active a img,
.dashboard-left footer ul li.active a img {
  display: none;
}

.dashboard-left menu ul li.active a img+img,
.dashboard-left footer ul li.active a img+img {
  display: block;
}

.dashboard-left menu ul.submenu {margin-left: 85px;}
.dashboard-left menu ul.submenu li a{padding: 10px 10px 10px 28px; font-size: 14px;}
.dashboard-left menu ul.submenu li a:before{
  content: ''; width: 14px; height: 14px; background-color: #fff;
  position: absolute; left: 0; top: 12px; transform: inherit; border-radius: 100%;
}
.dashboard-left menu ul.submenu li.avtive a,
.dashboard-left menu ul.submenu li a:hover{color: #75FF36;}

.dashboard-left menu ul.submenu li.avtive a:before,
.dashboard-left menu ul.submenu li a:hover:before{
  background-color: #75FF36;
  border: 0px solid transparent;
}

.dashboard-left menu ul.submenu li:before{display: none;}

.dashboard-left footer {
  background-color: #1F2645;
  margin-top: auto;
  padding: 10px 0;
}

.dashboard-left footer ul li {
  position: relative;
}

.dashboard-left footer ul li a {
  padding: 18px 10px 18px 50px;
}

.dashboard-left footer ul li a:hover:before {
  display: none;
}

.dashboard-right .top-head .navToggle{
  display: none;
}

.dashboard-wrap [class*=cta-] {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  position: relative;
  border: 0px;
  outline: none;
  cursor: pointer;
  padding: 12px 20px;
  letter-spacing: 0.5px;
  white-space: nowrap;
  font-weight: 400;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  font-size: 14px;
}

.dashboard-wrap [class*=cta-].small {
  font-weight: 400;
}

.dashboard-wrap [class*=cta-].full {
  display: block;
  width: 100%;
}

.dashboard-wrap [class*=cta-] .fa-solid,
.dashboard-wrap [class*=cta-] img {
  margin-right: 5px;
}

.dashboard-wrap .cta-red-border {border: 1px solid #e24c3e; color: #e24c3e; font-weight: 700; background: transparent;}
.dashboard-wrap .cta-red-border:hover{border: 1px solid #f06464; color: #f06464;}
.dashboard-wrap .cta-green-border {border: 1px solid var(--color-primary2); color: var(--color-primary2); font-weight: 700; background: transparent;}

.dashboard-wrap .cta-red-border.med,
.dashboard-wrap .cta-green-border.med{padding: 12px 20px; font-size: 14px; font-weight: 400;}
.dashboard-wrap .cta-red-border.small,
.dashboard-wrap .cta-green-border.small{padding: 7px 14px; font-size: 13px; font-weight: 400;}


.dashboard-wrap .cta-red {
  background: var(--color-e24c3e);
  color: var(--color-white);
  transition: opacity 0.3s;
}
.dashboard-wrap .cta-red:hover {
  background: #f06464;
  color: var(--color-white);
}
.dashboard-wrap .cta-green {
  background: var(--color-primary2);
  color: var(--color-black);
  transition: opacity 0.3s;
}

.dashboard-wrap .cta-green:hover {
  background: #ECB262;
  /* Old browsers */
  background: linear-gradient(90deg, #ECB262 0%, #C285AC 35%, #903FD7 60%, #9F5FEA 100%);
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, #ECB262 0%, #C285AC 35%, #903FD7 60%, #9F5FEA 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, #ECB262 0%, #C285AC 35%, #903FD7 60%, #9F5FEA 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(left, #ECB262 0%, #C285AC 35%, #903FD7 60%, #9F5FEA 100%);
  /* IE10+ */
  background: linear-gradient(to right, #ECB262 0%, #C285AC 35%, #903FD7 60%, #9F5FEA 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$color1', endColorstr='$color2', GradientType=1);
  /* IE6-9 */
  color: var(--color-white);
}

.dashboard-wrap .cta-green.med{padding: 12px 20px; font-size: 14px; font-weight: 400; width: auto;}
.dashboard-wrap .cta-green.small{padding: 7px 14px; font-size: 13px; font-weight: 400; width: auto;}

.dashboard-wrap .cta-white {
  background: var(--color-white);
  color: var(--color-black);
  transition: opacity 0.3s;
}

.dashboard-wrap .cta-white:hover {
  background: #ECB262;
  /* Old browsers */
  background: linear-gradient(90deg, #ECB262 0%, #C285AC 35%, #903FD7 60%, #9F5FEA 100%);
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, #ECB262 0%, #C285AC 35%, #903FD7 60%, #9F5FEA 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, #ECB262 0%, #C285AC 35%, #903FD7 60%, #9F5FEA 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(left, #ECB262 0%, #C285AC 35%, #903FD7 60%, #9F5FEA 100%);
  /* IE10+ */
  background: linear-gradient(to right, #ECB262 0%, #C285AC 35%, #903FD7 60%, #9F5FEA 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$color1', endColorstr='$color2', GradientType=1);
  /* IE6-9 */
  color: var(--color-white);
}

.dashboard-wrap .cta-light {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid var(--color-white);
  min-width: 112px;
}

.dashboard-wrap .cta-light:hover {
  background: var(--color-hover2);
  color: var(--color-black);
  border-color: transparent;
}

.dashboard-wrap .cta-dark {
  border: 1px solid var(--color-white);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  transition: all 0.4s ease;
  color: var(--color-white);
  background-image: linear-gradient(#030a27, #030a27), linear-gradient(90deg, #FFE290, #9F6DAB, #854FCB);
  border-color: transparent;
}

.dashboard-wrap .cta-dark:hover {
  background: var(--color-primary2);
  color: var(--color-black);
  border-color: transparent;
}
.dashboard-wrap .cta-dark.with-icon{display: flex; align-items: center;}
.dashboard-wrap .cta-dark.with-icon .fa-download{margin-right: 8px;}

.dashboard-wrap .cta-gradient {
  background: #ECB262;
  /* Old browsers */
  background: linear-gradient(90deg, #ECB262 0%, #C285AC 35%, #903FD7 60%, #9F5FEA 100%);
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, #ECB262 0%, #C285AC 35%, #903FD7 60%, #9F5FEA 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, #ECB262 0%, #C285AC 35%, #903FD7 60%, #9F5FEA 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(left, #ECB262 0%, #C285AC 35%, #903FD7 60%, #9F5FEA 100%);
  /* IE10+ */
  background: linear-gradient(to right, #ECB262 0%, #C285AC 35%, #903FD7 60%, #9F5FEA 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$color1', endColorstr='$color2', GradientType=1);
  /* IE6-9 */
  color: var(--color-white);
  border: 1px solid var(--color-white);
  min-width: 112px;
}

.dashboard-wrap .cta-gradient:hover {
  background: var(--color-hover2);
  color: var(--color-black);
  border-color: transparent;
}

.dashboard-wrap .cta-border {
  padding: 10px;
  border: 1px solid var(--color-white);
  color: var(--color-ffffff);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  transition: all 0.4s ease;
}

.dashboard-wrap .cta-border:hover {
  color: var(--color-white);
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  box-shadow: 0 0 9px 0 var(--color-854fcb);
  background-image: linear-gradient(#030a27, #030a27), linear-gradient(90deg, #FFE290, #9F6DAB, #854FCB);
  border-color: transparent;
}

.dashboard-wrap .cta-border-w {
  border: 1px solid var(--color-white);
  transition: all 0.4s ease;
  color: var(--color-white);
  background-color: transparent;
}

.dashboard-wrap .cta-border-w:hover {
  background: var(--color-hover2);
  color: var(--color-white);
}

.dashboard-wrap .text-link {
  color: var(--color-primary2);
}

.dashboard-wrap .text-link:hover {
  text-decoration: underline;
}

.dashboard-wrap .text-link_grey {
  color: rgba(255, 255, 255, 0.6);
}

.dashboard-wrap .text-link_purple {
  color: var(--color-hover2);
}

.dashboard-wrap .text-link_purple:hover {
  color: var(--color-primary2);
}

.bold {
  font-weight: 700;
}

.dropdown {
  width: 210px;
  min-width: 210px;
  position: relative;
}

.dropdown_green {
  background: var(--color-primary2);
  color: var(--color-black);
  position: relative;
  z-index: 1;
  border: 0px;
  cursor: pointer;
  padding: 12px 20px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  display: block;
}

.dropdown_green:after {
  content: "\f04b";
  font-weight: 700;
  font-family: "Font Awesome 6 Pro";
  line-height: 1;
  text-rendering: auto;
  position: absolute;
  top: 18px;
  right: 20px;
  font-size: 12px;
  color: var(--color-black);
  -webkit-transform: rotateZ(90deg);
  -moz-transform: rotateZ(90deg);
  -ms-transform: rotateZ(90deg);
  -o-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}

.dropdown .acc-info {
  background: var(--color-primary2);
  position: absolute;
  top: 25px;
  z-index: 0;
  width: 100%;
  padding: 20px 20px 10px 20px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.dropdown .acc-info ul li a {
  color: var(--color-black);
  display: block;
  padding: 5px 0;
}

.dropdown .acc-info ul li a:hover {
  color: var(--color-white);
}

.dropdown.acc.active .acc-head:after {
  -webkit-transform: rotateZ(150deg);
  -moz-transform: rotateZ(150deg);
  -ms-transform: rotateZ(150deg);
  -o-transform: rotateZ(150deg);
  transform: rotateZ(150deg);
}

.dropdown_select {
  background: var(--color-primary2);
  color: var(--color-black);
  position: relative;
  z-index: 1;
  cursor: pointer;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  display: block;
}

.dropdown_select .form-select {
  border: 0px;
  padding: 12px 20px;
  width: 210px;
}

.social-media:after {
  content: "";
  display: table;
  clear: both;
}

.social-media ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.social-media ul li a {
  display: block;
  text-align: center;
  position: relative;
  background-color: var(--color-primary2);
  color: var(--color-black);
  width: 26px;
  height: 26px;
  line-height: 26px;
  -webkit-border-radius: 150%;
  -moz-border-radius: 150%;
  border-radius: 150%;
  transition: all 0.4s ease;
}

.social-media ul li a .fab,
.social-media ul li a .fa-brands {
  font-size: 14px;
}

.social-media ul li a:hover {
  background-color: var(--color-white);
  color: var(--color-black);
}

.checkbox-list input[type=file]:focus,
.checkbox-list input[type=checkbox]:focus,
.checkbox-list input[type=radio]:focus {
  outline: none;
  outline-offset: 0;
}

.form-control::-webkit-input-placeholder {
  color: var(--color-999999);
}

.form-control::-moz-placeholder {
  color: var(--color-999999);
}

.form-control::-ms-placeholder {
  color: var(--color-999999);
}

.form-control::placeholder {
  color: var(--color-999999);
}

select:focus {
  border-color: transparent !important;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0) !important;
}

.field {
  margin: 0 0 20px;
}

.field label {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  color: var(--color-white);
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}

.field .error {color: #ff3131; padding-top: 5px;}
.field > p, .field > span {color: #ff3131; font-size: 14px; display: block; padding-top: 5px;}
.field .changePass-grid > p{color: #ff3131; font-size: 14px; display: block; padding-top: 5px;}
.field .changePass-grid span {color: #ff3131; font-size: 14px; display: block; padding-top: 5px;}
.field .changePass-grid span.toggle-password{color: #fff;}
.appearance-select-color .botname_block span {color: #ff3131; font-size: 14px; display: block; padding-top: 5px;}


.field .error+.error {
  color: var(--color-ff0800);
  font-weight: 400;
  border-color: transparent;
  font-size: 14px;
  margin: 5px 0 0;
}

.form-control,
.form-select {
  display: block;
  width: 100%;
  padding: 10px 15px;
  line-height: 1.5;
  color: var(--color-text);
  background-color: var(--color-010214);
  border: 1px solid var(--color-white);
  border-radius: 10px;
  outline: none;
  font-size: 14px;
  color-scheme: dark;
  position: relative;
}
.form-select option{
  color: var(--color-text);
  background-color: var(--color-010214);
}

.form-control:focus,
.form-select:focus {
  border: 1px solid var(--color-white) !important;
  color: var(--color-white);
  background-color: transparent;
}

.form-select {
  --bs-form-select-bg-img: 'url(../images/icons/select-arrow-icon.svg)';
  background-image: url(../images/icons/select-arrow-icon.svg);
  background-size: 10px 8px;
  /*width: 210px;*/
  font-size: 14px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}
.form-select.with-arrow{
  --bs-form-select-bg-img: 'url(../images/icons/select-arrow-icon-b.svg)';
  background-image: url(../images/icons/select-arrow-icon-b.svg);
}
.form-select.w-240{width: 240px;}
.w-400{width: 400px !important;}
.w-350{width: 350px !important;}
.w-300{width: 300px !important;}
.w-240{width: 240px !important;}
.w-200{width: 200px !important;}
.w-180{width: 180px !important;}
.w-160{width: 160px !important;}
.w-140{width: 140px !important;}

/* Checkbox Css Style */
.form-check,
.form-radio {
  position: relative;
  padding-left: 43px;
  font-weight: 300;
  width: 100%;
  display: block;
  font-size: 16px;
}

.form-check .error,
.form-radio .error {
  border-color: var(--red-color);
}

.form-check .error+label,
.form-radio .error+label {
  display: none !important;
}

.form-check label,
.form-radio label {
  cursor: pointer;
}

.form-check .checkbox,
.form-radio .checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: absolute;
  top: 3px;
  right: 0;
  bottom: 0;
  left: 12px;
  z-index: 9;
  height: 16px;
  width: 16px;
  transition: all 0.15s ease-out 0s;
  display: inline-block;
  outline: none;
  background: var(--color-010214);
  border: 1px solid var(--color-white);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.form-check .checkbox:checked,
.form-radio .checkbox:checked {
  background: var(--color-white);
  border: 1px solid var(--color-white);
}

.form-check .checkbox:checked:before,
.form-radio .checkbox:checked:before {
  content: '✔';
  height: 16px;
  width: 16px;
  line-height: 16px;
  position: absolute;
  display: inline-block;
  font-size: 12px;
  text-align: center;
  color: #23283e;  
}

.form-radio .radiobox {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: absolute;
  top: 4px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  transition: all 0.15s ease-out 0s;
  display: inline-block;
  outline: none;
  cursor: pointer;
  border: 1px solid var(--color-57B3B5);
}

.form-radio .radiobox:checked:before {
  content: '';
  height: 10px;
  width: 10px;
  display: inline-block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-color: 1px solid var(--color-57B3B5);
  background-color: var(--color-57B3B5);
}

/* End Css */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 18px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch input:checked+.toggleSlider {
  background-color: var(--color-hover2);
}

.toggle-switch input:checked+.toggleSlider:before {
  transform: translateX(24px);
}

.toggle-switch .toggleSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #4b4b4b;
  transition: 0.4s;
  border-radius: 14px;
}

.toggle-switch .toggleSlider:before {
  position: absolute;
  content: "";
  height: 11px;
  width: 11px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.changePass-grid {
  position: relative;
}

.changePass-grid .form-control {
  padding-right: 30px;
}

.changePass-grid .toggle-password {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 15px;
  z-index: 9;
  font-size: 14px;
}

.changePass-grid.active .toggle-password .fa-regular:before {
  content: "\f06e";
}

textarea.form-control {
  height: auto;
}

.errorMessage,
.successMessage {
  padding: 0 0 0 28px;
  background-color: transparent;
  border-color: transparent;
  position: relative;
  display: inline-block;
  line-height: 1.2;
  font-weight: 300;
  min-height: 24px;
  margin-top: 20px;
  font-size: 16px;
}

.errorMessage:before,
.successMessage:before {
  display: inline-block;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  font-size: 18px;
  position: absolute;
  left: 0px;
  top: 2px;
}

.errMsg{color: #f70017;}
.errorMessage {color: #f70017;}

.errorMessage:before {
  content: "\f071";
}

.successMessage {
  color: #00b80f;
}

.successMessage:before {
  content: "\f164";
}

@media screen and (min-width: 768px) {
  .field {
    margin: 0 0 25px;
  }
}

.react-flow__node-default{color: #fff !important; background-color: var(--color-secondary2) !important; border-radius: 10px !important; line-height: 16px;}
.react-flow__edges svg{max-width: none;}
.react-flow__edgelabel-renderer button{width: 20px; height: 20px; line-height: 16px; box-shadow: 0 0 6px 2px var(--color-secondary2); font-family: Arial;}


.react-flow__panel.react-flow__minimap.right{background-color: #1F2645;}
.react-flow__panel.left .react-flow__controls-button{background-color: #1F2645; border-color: #000;}
.react-flow__attribution.right{display: none; opacity: 0;}

.card .card-body .justify-content-end div p{border-bottom-right-radius: 0 !important;}
.card-footer .card-footer_box .type-text{font-size: 14px; display: block; padding: 12px 10px 12px 10px; margin-right: 5px;}

.rounded-20{border-radius: 20px !important;}
.rounded-30{border-radius: 30px !important;}
.rounded-40{border-radius: 40px !important;}

.pr-10{padding-right: 10px;}

/*# sourceMappingURL=coolbiz_dashboard.css.map */

/* ChatBox Css */
.chat-template-wrap{
  /*background: rgb(255 0 0 / 30%);
  width: 100%;
  height: 100%;
  position: fixed; */
}
.chat-template{
    width: 365px; height: 60vh; z-index: 100;
    position: fixed; right: 60px; bottom: 0px;
    border-radius: 16px; overflow: hidden;
}
.live-chatbox_grid {
  position: relative; z-index: 1; top: 0; left: 0;
  width: 100%; height: 100%;  
}
.live-chatbox_message {
  height: calc(100% - 78px); width: 100%;
  display: flex; flex-direction: column;
  box-sizing: border-box; overflow-y: auto;
  margin-bottom: 78px;
}
.live-chatbox_message .message_box{align-items:flex-start;}
.live-chatbox_footer {
  bottom: 0; position: absolute; padding: 20px; width: 100%;
  display: flex; align-items: center; gap: 8px;
  background-color: #fff;
}

.live-chatbox_footer.live-chat_one{background: #f8f8f8;}
.live-chatbox_footer.live-chat_one .form-control-lg{
  border: 0; width: 100%; font-size: 14px; display: block; 
  padding: 12px 10px 12px 10px; background: #6c757d; color: #fff;
}
.live-chatbox_footer.live-chat_one .form-control-lg::-webkit-input-placeholder {color: #fff;}
.live-chatbox_footer.live-chat_one .form-control-lg::-moz-placeholder {color: #fff;}
.live-chatbox_footer.live-chat_one .form-control-lg::-ms-placeholder {color: #fff;}
.live-chatbox_footer.live-chat_one .form-control-lg::placeholder {color: #fff;}

.live-chatbox_footer .live-chat_one .chatTime{color: #dbdbdb;}

.live-chatbox_grid .chatTime{font-size: 11px; margin-top: 5px; display: block;}

.live-chatbox_footer.live-chat_two{background: #f8f8f8;}
.live-chatbox_footer.live-chat_two .form-control-lg{
  border: 0; width: 100%; font-size: 14px; display: block; 
  padding: 12px 10px 12px 10px; background: #fff;
}

/* Add New Css for live chat (03-01-2025) */

/* Live Chat 1 */
.chatBot_live_one .MuiInputBase-root.MuiOutlinedInput-root{padding-left:2px;}
.chatBot_live_one .MuiFormControl-root{background-color: #6c757d; border-radius: 0.5rem; border: 0px;}
.chatBot_live_one .MuiFormControl-root input.MuiInputBase-input{color: #ffffff !important; padding: 12px 10px !important; height: 43px;}
.chatBot_live_one .MuiTypography-body1{color: #ffffff !important;}
.chatBot_live_one .MuiOutlinedInput-notchedOutline {border: 0px !important; border-radius: 0px !important;}
.chatBot_live_one .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{border: 0px;}
.chatBot_live_one .form-control-lg{min-height: 43px;}

/* Live Chat 2 */
.chatBot_live_two { background: #f8f8f8; }
.chatBot_live_two .form-control-lg{
  border: 0; width: 100%; font-size: 14px; display: block;
  padding: 12px 10px 12px 10px; background: #ffffff;
  color: #000000; font-family: "Poppins", arial, sans-serif;
}
.chatBot_live_two .form-control-lg:focus {border: 0px; outline: none;}
.chatBot_live_two .form-control-lg::-webkit-input-placeholder {color: #000000;}
.chatBot_live_two .form-control-lg::-moz-placeholder {color: #000000;}
.chatBot_live_two .form-control-lg::-ms-placeholder {color: #000000;}
.chatBot_live_two .form-control-lg::placeholder {color: #000000;}

.chatBot_live_two .MuiFormControl-root input.MuiInputBase-input{color: #000000 !important; padding: 12px 10px 12px 10px !important; height: 43px;}
.chatBot_live_two .MuiTypography-body1{color: #000000 !important;}
.chatBot_live_two .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{border: 0px;}    
/* End Css */

.live-chat-first .left-live-chat{border: 1px solid #dee2e6; border-bottom-left-radius: 0 !important;}
.live-chat-first .right-live-chat{border: 1px solid #dee2e6; border-bottom-right-radius: 0 !important;}
.live-chat-first .right-live-chat .chatTime{text-align: right; display: block;}
.live-chat-first .chatflow-option .cta-border-w{border-radius: 5px; padding: 5px 10px; font-size: 12px;}

.live-chat-second .chatflow-option .cta-border-w{border-radius:50px; padding: 5px 10px; font-size: 12px;}
.live-chat-second .left-live-chat .chatTime{margin-left: 8px;}
.live-chat-second .right-live-chat .chatTime{text-align: right; margin-right: 8px;}

.appearance-grid .chatTime{font-size: 11px; margin-top: 5px; color: #010314; display: block;}
/* End Css */

/* Pagination Css */

.pagination {justify-content: center; margin-top: 20px;}
.pagination.disabled a{color: rgba(255, 255, 255, 0.2);}
.pagination a {
  width: 40px; height: 36px; padding: 6px; text-align: center;
  background: #1F2645; color: #fff; border-right:1px solid #394474;
}
.pagination a:first-child, .pagination a:last-child{font-size: 24px; line-height: 24px; background-color: #394474;}
.pagination a:hover{opacity: 0.8;}

.pagination .active a{
  background-color: var(--color-secondary2);
  border-color: var(--color-secondary2);
  color: var(--color-white);
}
/* End Css */

/* Pagination 2 Css */
.pagination-wrap{position: relative;}
.pagination_wrap{position: relative; display: flex; align-items: center; margin-top: 30px;}
.pagination_wrap .pagination-info{-ms-flex: 226px; flex: 0 0 226px; max-width: 226px;}
.pagination-table {
  border-radius: 0px; display: flex; justify-content: center; margin: 0 auto; background: #1F2645;
  padding: 10px; border-radius: 8px; width: fit-content; gap: 10px;
}
.pagination-table button:disabled{color: rgba(255, 255, 255, 0.2); outline: none;}
.pagination-table button {
  width: 30px; height: 30px; text-align: center;
  background: transparent; color: #fff; border: 0; outline: none;
}
.pagination-table button:first-child,
.pagination-table button:last-child{position: relative; padding: 0 10px;}

.pagination-table button:hover{color: #a66cff;}
.pagination-table button.active{
  background-color: var(--color-secondary2); border-color: var(--color-secondary2); 
  color: #ffffff; font-weight: 700; border-radius: 4px;
}
.pagination-table nav ul li{margin: 0 !important;}

.MuiPagination-ul .MuiPaginationItem-root{color: #fff;}
/* End Css */

.botname_block{padding:15px 10px; margin-bottom: 20px; position: relative;}
.botname_block h3{line-height: 100%;}
.botname_block .botname-block-form{position: relative;}
.botname_block .botname-block-form input{width: 100%; background-color: #29325a; color: #fff; border: 0; padding: 5px 34px 5px 10px; border-radius: 40px;}
.botname_block .botname-block-form.active input{background-color: #57608b;}
.botname_block .botname-block-form img{position: absolute; bottom: 9px; right: 11px;}
.botname_block .cta-green{
  position: absolute; top: 12px; right: 12px; padding: 0px; padding: 5px 10px;
  border: 0px; outline: none; font-size: 12px; text-transform: capitalize; font-weight: 300;
}


.mychatbot-loader,
.page-loader{
  height: 70vh;
  align-items: center;
  justify-content: center;
  display: flex;
}
.mychatbot-loader img,
.page-loader img{
  max-width: 100px;
}
.mychatbot-loader{
  height: 90%;  
}

.swal2-popup{padding: 50px 30px; background-color: #3a4575;}
.swal2-popup .swal2-title{line-height: normal; font-size: 24px; color: #fff;}
.swal2-popup .swal2-actions{margin-top: 2em;}
/* End Css */

/* Tooltips Css */
.subscription-plan header.with-tooltips h3{padding-right:50px; position: relative;}
.tooltips{position: absolute; top: 0; right: 0; width: 30px;}
.tooltips .tooltips_icon{font-size: 24px;}
.tooltips .tooltips_text{
  width: 300px; font-size: 14px; position: absolute; left: 30px; top: 50%; transform: translateY(-50%);
  background-color: #2a3256; padding: 10px 15px; border-radius: 6px;  line-height: 120%; display: none;
}
.tooltips .tooltips_text p{margin: 0; font-weight: 300; line-height: 120%;}
.tooltips:hover .tooltips_text{display: block;}
/* End Css */

.bottom-chatbot_message{
  position: fixed; bottom: 6%; right: 6%;
  z-index: 9; display: flex; align-items: center; gap: 0 10px;
  background-color: #6331a3; padding: 12px 10px; border-radius: 14px;
  border-bottom-right-radius: 0px; font-size: 14px;
}
.bottom-chatbot_message figure{width: 36px; border-radius: 50%; overflow: hidden;}
.bottom-chatbot_message figure img{width: 36px; height: 36px; object-fit: cover;}

.bottom-chatbot_message2{
  position: fixed; margin: 5px;
  z-index: 9; display: flex; align-items: center;
  border: 2px solid #ffffff; border-radius: 50%;
}
.bottom-chatbot_message2 figure{width: 44px; border-radius: 50%; overflow: hidden; cursor: pointer; position: relative;}
.bottom-chatbot_message2 figure:before{
  content: ''; position: absolute; left: 0; top: 0; width: 100%; height: 100%;
  background-color: rgba(0, 0, 0, 0.1); z-index: 1;
}
.bottom-chatbot_message figure img{width: 44px; height: 44px; object-fit: cover;}
.bottom-chatbot_message2 .messageBox{
  font-size: 14px; background: #fff;
  color: #000; padding: 24px 12px 12px; border-radius: 10px;
}
.bottom-chatbot_message2 .messageBox p{margin-bottom: 0; line-height: 1.6;}
.bottom-chatbot_message2 .closeBox{
  background-color: #ccc; width: 22px; height: 22px; font-weight: 500;
  border-radius: 50px; display: flex; align-items: center; font-size: 12px;
  justify-content: center; position: absolute; right: 5px; top: 5px;
}
.bottom-chatbot_message2 .closeBox:hover{background-color: #000000; color: #fff;}


/* */
.dashboard-wrap .profile-photo_block {position: relative; width: 60px;}
.dashboard-wrap .profile-photo_block .file-upload-grid{
  background-color: rgba(255, 255, 255, 0.8); position: relative;
  width: 36px; height: 36px; border-radius: 36px; 
  display: flex; align-items: center; justify-content: center;
  position: absolute; bottom: -6px; right: -6px;
}
.dashboard-wrap .profile-photo_block .file-upload-grid input{position: absolute; left: 0; top: 0; opacity: 0;}
.dashboard-wrap .profile-photo_block .file-upload-grid img{width: 20px; bottom: 8px; right: 8px;}
.dashboard-wrap .profile-photo_block .file-upload-grid .errMsg{position: absolute; left: 60px; top: -20px; font-size: 14px; width: 200px;}
.dashboard-wrap .profile-photo_block figure {
  width: 60px; height: 60px; border-radius: 50%; border: 1px solid #3e3e3e;
  -webkit-border-radius: 50%; -moz-border-radius: 50%;  
}
.dashboard-wrap .profile-photo_block figure img{height: 60px; width: 100%; object-fit: cover; bottom: 0px; right: 0px;}
.dashboard-wrap .profile-photo_block .group-link {display: flex; align-items: flex-end; gap: 20px; padding-bottom: 10px;}
/* */

.referralLink .group-form .with-icon{position: relative; width: 400px;}
.referralLink .group-form .with-icon .fa-clone{position: absolute; right: 15px; top: 14px;}
.referralLink .group-form .with-icon .form-control{padding-right: 40px; color: var(--color-854fcb); border-radius:50px;}
.referralLink .group-form .with-icon .form-control:disabled {background-color: #000; opacity: 0.5;}

.dashboard-wrap .filter-wrap{display: flex; align-items: center; gap: 20px;}
.dashboard-wrap .filter-wrap_block{display: flex; align-items: center; gap: 10px;}
.dashboard-wrap .filter-wrap_block .form-control{border-radius: 30px;}
.dashboard-wrap .filter-wrap_block select{border-radius: 30px;}

.dashboard-nav-close{
  position: absolute; right: 15px; top: 15px;
  z-index: 9; display: none;
  background: transparent;
  outline: none;
  border: 0;
}

/* access-management */
.dashboard-wrap .access-management .table{border-color: #5c5f6e; border-spacing: 0px;} 
.dashboard-wrap .access-management .table thead tr th{border-color: var(--color-secondary2); padding: 16px;}

.dashboard-wrap .access-management .table tbody tr th{padding: 16px;}
.dashboard-wrap .access-management .table tbody th:first-child,
.dashboard-wrap .access-management .table tbody th:last-child{border-radius: 0px; border: 0px;}

.dashboard-wrap .access-management .table td:first-child,
.dashboard-wrap .access-management .table td:last-child {border-radius: 0px;}
.dashboard-wrap .access-management .table td:last-child {border-right: 0px;}
.dashboard-wrap .access-management .table td {border-left: 0px; border-bottom-width: 1px; padding: 16px;}
.dashboard-wrap .access-management .table tr:last-child td {border-bottom-width: 0px;}

.dashboard-wrap .access-management .form-check{display: flex; align-items: center; gap: 15px; padding: 0;}
.dashboard-wrap .access-management .form-check.ms-5 {margin-left: 2.4rem !important;}
.dashboard-wrap .access-management .form-check.center{justify-content: center;}
.dashboard-wrap .access-management .form-check .checkbox{left: 0; top: 0; position: relative; width: 24px; height: 24px;}
.dashboard-wrap .access-management .form-check .checkbox:before {height: 24px; width: 24px; line-height: 24px; font-size: 18px;}
.dashboard-wrap .access-management .form-check .checkbox:disabled{background-color: #494c5f; border-color: #494c5f;}

.dashboard-wrap .access-management .checkbox-with-link{display: flex; align-items: center; gap: 15px; padding: 0;}
.dashboard-wrap .access-management .checkbox-with-link img+img{display: none;}
.dashboard-wrap .access-management .checkbox-with-link:hover img{display: none;}
.dashboard-wrap .access-management .checkbox-with-link:hover img+img{display: block;}

.dashboard-wrap .access-management .form-check .checkbox:checked + .checkbox-with-link{color: var(--color-hover2);}
.dashboard-wrap .access-management .form-check .checkbox:checked + .checkbox-with-link img{display: none;}
.dashboard-wrap .access-management .form-check .checkbox:checked + .checkbox-with-link img+img{display: block;}

.dashboard-wrap .access-management .form-check label.with-circle{position: relative; padding-left: 30px;}
.dashboard-wrap .access-management .form-check label.with-circle:before{
  content: ''; width: 16px; height: 16px;
  border-radius: 100%; background-color: #fff;
  position: absolute; left: 0; top: 4px;
}
/* End Css */

/* Notification Page */
.notification_wrap{position: relative; margin-top: 30px;}
.notification_wrap .notification_item{
  position: relative; background-color: var(--color-secondary2); display: block;
  border-radius: 15px; margin: 20px 0; padding: 10px 75px 10px 70px; color: var(--color-ffffff);
}
.notification_wrap .notification_item:hover{color: var(--color-ffffff);}
.notification_wrap .notification_item figure{
  background-color: #1F2645; width: 40px; height: 40px; border-radius: 36px;
  display: flex; align-items: center; justify-content: center; padding: 8px;
  position: absolute; left: 15px; top: 12px; margin: 0;
}
.notification_wrap .notification_item h5{font-weight: 600; margin: 0 0 5px; line-height: 20px;}
.notification_wrap .notification_item p{margin: 0; line-height: normal; font-size: 14px;}
.notification_wrap .notificationTime{position: absolute; right: 15px; top: 15px; font-size: 13px; color: #ccc;}

.notification_wrap .notification_item.inactive,
.notification_wrap .notification_item.disabled{opacity: 0.4; pointer-events: none; cursor: default;}

.notification-grid{
  display: flex; gap: 15px; padding: 10px 0;
  width: 100%; max-width: 400px;
}
.notification-grid figure{
  background-color: #7b46cd; border-radius: 36px; margin: 0; 
  display: flex; align-items: center; justify-content: center; padding: 8px;
  flex: 0 0 36px; width: 36px; height: 36px;
}
.notification-grid .notification_info h5{font-weight: 500; margin: 0 0 5px; line-height: 20px; font-size: 16px; color: #fff;}
.notification-grid .notification_info p{margin: 0; line-height: normal; font-size: 14px; color: #fff;}
/* End Css */

.paper-plane_icon{background: transparent; padding: 0px; outline: none; border: 0px;}
.paper-plane_icon img{width: 24px;}
.live-chat-close{max-width: 14px; cursor: pointer;}

.statusToggle .toggle-switch{margin-left: -15px;}

.user-details-form .field .custom-select__control{background-color: #010214; padding: 4px 0; border-radius: 10px;}
.user-details-form .field .custom-select__multi-value{background-color: #3b4468;}
.user-details-form .field .custom-select__multi-value__label{color: #ffffff;}


/* Popover Css */
.user-details-form .field .popover_wrpa{position: relative;}
.user-details-form .field .popover_icon{padding: 0; margin: 0 0 0 5px; min-width: auto; background: transparent; font-size: 22px;}
.user-details-form .field .popover_info{
  position: absolute; background: #1F2645; z-index: 1; padding: 15px; 
  border-radius: 10px; width: 400px; left: 40px; top: 0px;
}
.user-details-form .field .popover_info ol{padding-left: 12px; padding-top: 10px;}
.user-details-form .field .popover_info ol li{padding: 4px 0;}
.user-details-form .field .popover_info ol li a{color: #75ff37;}
.user-details-form .field .popover_info ol li a:hover{text-decoration: underline;}
.user-details-form .field .popover_close{
  position: absolute; top: 10px; right: 10px; background: transparent; z-index: 1; 
  border: 0px; outline: none; width: 24px;
}
/* End Css */

.dashboard-wrap .choose-color_style{position: relative; margin-top: 10px; width: 220px;}
.dashboard-wrap .choose-color_style .choose-color_style_modal{
  background-color: #ffffff; position: absolute; 
  right: 5px; top: 5px; width: 14px; z-index: 99;
}
.dashboard-wrap .choose-color_style .sketch-picker{padding-top: 25px !important;}

.dashboard-wrap .description-with-toggle{display: flex; gap: 10px;}
.dashboard-wrap .description-with-toggle label{width: auto;}
.dashboard-wrap .description-with-toggle .toggle-switch{width: 42px; margin-top: 3px;}


@media screen and (max-width: 1200px) {
  .dashboard-wrap .dashboard-left{
    position: fixed; padding: 0px; top: 0; left: 0;
    height: 100%; z-index: 100; overflow: auto;
    max-width: 360px; width: 100%; transition: .5s;
    transform: translateX(-100%);
  }
  .dashboard-wrap .dashboard-left { scrollbar-width: thin; scrollbar-color: var(--color-854fcb) #2c2e3b;}
  .dashboard-wrap .dashboard-left::-webkit-scrollbar {width: 8px;}
  .dashboard-wrap .dashboard-left::-webkit-scrollbar-track {background: #2c2e3b;}
  .dashboard-wrap .dashboard-left::-webkit-scrollbar-thumb {background-color: var(--color-854fcb); border-radius: 10px;}
  .dashboard-wrap .dashboard-left::-webkit-scrollbar-thumb:hover {background-color: #cb4f4f;}

  .dashboard-wrap .dashboard-left.open-nav{
    transform: translateX(0);
  }

  .dashboard-wrap .dashboard-right {
    padding: 20px 0px;
  }

  .dashboard-wrap .dashboard-right .top-head{
    padding-left: 60px;
  }
  
  .dashboard-right .top-head .navToggle{
    display: block;
    position: absolute; left: 0px; 
  }

  .chatbot-history-grid .appearance-grid .box-24 {width: 33%;}
  .chatbot-history-grid .appearance-grid .box-38 {width: 33%;}
  .dashboard-nav-close{display: inline-block;}

  .dashboard-wrap .my-profile-grid .myprofile-block ul li {gap: 30px;}
  
  .dashboard-wrap .my-profile-grid .myprofile-block ul li .info_left {-ms-flex: 180px; flex: 0 0 180px; max-width: 180px;}
}

@media screen and (max-width: 992px) {
  .dashboard-wrap .dashboard-feature {gap: 15px; justify-content: center !important;}
  .dashboard-wrap .dashboard-feature .cards {width: 48%;}
}

@media screen and (max-width: 767px) {
  .dashboard-wrap .dashboard-right .top-head_left .page-search .form-control {background-color: #212949;}
  .dashboard-wrap .dashboard-right .top-head_left {-ms-flex: 220px; flex: 0 0 220px; max-width: 220px;}

  .dashboard-wrap .mychatbot-grid_lists .chatbot-list {width: 48%;}
  .dashboard-wrap .mychatbot-grid_lists {gap: 4%;}

  .dashboard-wrap .all-chatboat header h3,
  .dashboard-wrap .chatbot-history-grid header h3,
  .dashboard-wrap .dashboard-chart-grid header h6{margin-bottom: 15px;}
  .dashboard-wrap .dashboard-chart-grid header .dropdown_select{margin-bottom: 15px;}
  .dashboard-wrap .dashboard-chart-grid header .dropdown_select .form-select{width: 100%;}

  .dashboard-wrap .chatbot-history-grid header .group-form {gap: 12px; display: flex; flex-wrap: wrap;}

  .subscription-plan-info ul li{flex-direction: column; align-items: flex-start;}
  .subscription-plan-info .subscription-plan_right {width: 100%;}
  .subscription-plan-info .annual-agency-plan .subscription-plan_right {width: 100%; justify-content: space-between;}

  .appearance-grid{flex-wrap: wrap;}
  .chatbot-history-grid .appearance-grid .box-24,
  .chatbot-history-grid .appearance-grid .box-38 {width: 100%;}
  .dashboard-wrap .my-profile-grid .myprofile-block ul li .info_left {flex: 0 0 160px; max-width: 160px;}
  
}

@media screen and (max-width: 600px) {
  .dashboard-wrap .dashboard-feature {gap: 20px; margin-bottom: 30px;}
  .dashboard-wrap .dashboard-feature .cards {width: 100%; margin: auto; max-width: 293px;}

  .dashboard-wrap .my-profile-grid .myprofile-block ul li {padding: 15px 0;}
  .dashboard-wrap .my-profile-grid .myprofile-block ul li .info_left {flex: 0 0 100%; max-width:  100%;}
  .dashboard-wrap .my-profile-grid .myprofile-block ul li {gap: 10px; flex-wrap: wrap;}
  .dashboard-wrap .my-profile-grid .myprofile-block ul li .info_right input {padding-left: 0px;}
}