@charset "UTF-8";
@use "sass:list";
@use "sass:math";
@use "sass:math";

:root {
  /* Colors */
  --color-hover: #75ff37;
  --color-primary: #75ff37;
  --color-secondary: #75ff37;
  --body-text: #ffffff;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-fbfbfb: #fbfbfb;
  --color-a6a7ac: #a6a7ac;
  --color-999999: #999999;
  --color-262626: #262626;
  --color-010214: #010214;
}

/* .chat-template-wrap {
  display: none;
} */

.form-grid {
  display: flex;
}

.form-grid_left {
  -ms-flex: 48%;
  flex: 0 0 48%;
  max-width: 48%;
  padding: 20px 50px 50px;
}

.form-grid_right {
  -ms-flex: 52%;
  flex: 0 0 52%;
  max-width: 52%;
  background-repeat: no-repeat;
  background-position: top center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 0 30px;
}

.form-grid_gredient {
  background-color: #020823;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#020823), to(#00282D));
  background-image: -webkit-linear-gradient(top, #020823, #00282D);
  background-image: -moz-linear-gradient(top, #020823, #00282D);
  background-image: -ms-linear-gradient(top, #020823, #00282D);
  background-image: -o-linear-gradient(top, #020823, #00282D);
  background-image: linear-gradient(top, #020823, #00282D);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  position: relative;
  padding: 30px 40px;
  min-height: 300px;
  width: 100%;
  max-width: 510px;
  margin-left: auto;
  margin-right: auto;
}

.form-grid_block figure {
  text-align: center;
  margin-bottom: 50px;
}

.form-grid_block header {
  text-align: center;
}

.form-grid_block header h4 {
  font-weight: 600;
}

.form-grid_block header p {
  font-size: 12px;
}

.form-grid_block .forgot-pass {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  margin-top: -10px;
  margin-bottom: 20px;
}

.form-grid_block .forgot-pass a {
  color: var(--color-white);
}

.form-grid_block .forgot-pass a:hover {
  text-decoration: underline;
}

.form-grid_block .or-devider {
  position: relative;
  margin-bottom: 20px;
}

.form-grid_block .or-devider:after {
  content: '';
  width: 100%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 0;
}

.form-grid_block .or-devider span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  margin: auto;
  border: 1px solid var(--color-white);
  background-color: #011f2a;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.form-grid_block .login-with-social {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.form-grid_block .login-with-social .cta-border {
  width: 100%;
  max-width: 175px;
}

.form-grid_block .login-with-social .cta-border img {
  margin-right: 10px;
}

.form-grid_block .login-with-social .cta-border-tab {
  width: 100%;
  max-width: 175px;
}

.form-grid_block .login-with-social .cta-border-tab img {
  margin-right: 10px;
}

.form-grid_block .field .css-ot336b-MuiInputBase-root-MuiOutlinedInput-root {
  padding-left: 6px;
}

.form-grid_block .field .MuiOutlinedInput-notchedOutline {
  border: 1px solid var(--color-white);
  border-radius: 10px;
}

.form-grid_block .field .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--color-white);
}

.form-grid_block .field input.MuiInputBase-input {
  padding: 10px 15px;
  color: #fff;
}

.form-grid_block .field .MuiTypography-body1 {
  color: #fff;
}

.form-right-img-block {
  background-color: rgba(0, 0, 0, 0.73);
  width: 100%;
  max-width: 566px;
  height: 100%;
  margin: auto;
  padding: 45px 0px 0;
  display: flex;
  flex-direction: column;
}

.form-right-img-block .btn-grid {
  padding: 0 15px 30px;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-right-img-block header {
  text-align: center;
  margin-bottom: 40px;
}

.form-right-img-block header h2 {
  margin-bottom: 0;
}

.form-right-img-block header h2 span {
  display: block;
  position: relative;
}

.form-right-img-block header h2 span:after {
  content: '';
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -6px;
  z-index: 0;
  width: 204px;
  height: 10px;
  background: url("../public/images/connector/header-line.svg") no-repeat center;
}

.form-right-img-block header h3 {
  font-weight: 700;
  color: var(--color-primary);
}

.form-right-img-block figure {
  text-align: center;
}

.form-right-img-block.login figure {
  margin-top: auto;
}

.form-right-img-block.login figure img {
  max-width: 290px;
}

.form-right-img-block.create-acc figure {
  margin-top: auto;
  overflow: inherit;
}

.form-right-img-block.create-acc figure img {
  max-width: inherit;
  margin-left: -36px;
}

.loginForm .changePass-grid {
  position: relative;
}

.loginForm .changePass-grid .form-control {
  padding-right: 30px;
}

.loginForm .changePass-grid .toggle-password {
  position: absolute;
  top: 22px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 15px;
  z-index: 9;
  font-size: 14px;
}

.loginForm .changePass-grid.active .toggle-password .fa-regular:before {
  content: "\f06e";
}

.loginForm .changePass-grid.enter-otp-block input{
  border: 0; outline: none; width: 40px !important; height: 45px;
  border-radius: 10px; margin-right: 10px; color: #fff; background-color: #595f8f;
}
.loginForm .changePass-grid.enter-otp-block input[type="number"]:hover {
  -webkit-appearance: none; -moz-appearance: textfield; appearance: none; 
}
.loginForm .changePass-grid.enter-otp-block input[type="number"]::-webkit-outer-spin-button,
.loginForm .changePass-grid.enter-otp-block input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none; margin: 0;
}
.loginForm .changePass-grid.enter-otp-block input[type="number"]::-moz-spin-button {display: none;}

.features-grid {
  margin: 40px 0 50px;
}

.features-grid_list {
  position: relative;
  margin: 50px 0 0;
}

.features-grid_list ul li {
  padding: 20px 0;
  display: flex;
}

.features-grid_list ul li figure {
  display: flex;
  align-items: center;
  justify-content: center;
  -ms-flex: 126px;
  flex: 0 0 126px;
  max-width: 126px;
  height: 126px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid var(--color-white);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-image: linear-gradient(#030a27, #030a27), linear-gradient(180deg, #FFE290, #9F6DAB, #854FCB);
  border-color: transparent;
  box-shadow: 0 0 30px 0 #51228f;
}

.features-grid_list ul li aside {
  flex: 1 1 auto;
  padding-left: 40px;
}

.features-grid_list ul li aside h4 {
  font-size: 24px;
  font-weight: 400;
}

.features-grid_list ul li aside p {
  font-size: 14px;
}

@media screen and (max-width: 1200px) {

  .form-grid_left {
    -ms-flex: 55%;
    flex: 0 0 55%;
    max-width: 55%;
  }

  .form-grid_right {
    -ms-flex: 45%;
    flex: 0 0 45%;
    max-width: 45%;
  }

  .form-right-img-block.create-acc figure {
    margin-top: 0;
  }

  .form-right-img-block.create-acc figure img {
    width: 100%;
  }
}

@media screen and (max-width: 992px) {
  .form-grid {
    display: block;
  }


  .form-grid_left {
    -ms-flex: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0px;
  }

  .form-grid_right {
    -ms-flex: 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .form-right-img-block.login figure,
  .form-right-img-block.create-acc figure {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .features-grid ul li {
    padding: 10px;
    display: block;
  }

  .features-grid ul li figure {
    margin: 0 auto 20px;
  }

  .features-grid ul li aside {
    text-align: center;
    padding-left: 0px;
  }

  .features-grid ul li aside h4 {
    font-size: 20px;
  }

  .features-grid ul li aside p {
    font-size: 12px;
  }
}

.pricing-grid {
  margin: 40px 0 50px;
}

.pricing-grid .banner-home_text h2 {
  font-size: 72px;
  margin-bottom: 40px;
}

.pricing-grid_list {
  position: relative;
  padding: 80px 0 0;
  min-height: 600px;
}

.pricing-grid_list:before {
  content: '';
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 200px;
  z-index: -1;
  width: 240px;
  height: 240px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background-color: #37145D;
  box-shadow: 0px 0px 100px 100px #37145d;
}

.pricing-grid_list header {
  text-align: center;
  max-width: 750px;
  margin: 0 auto 50px;
}

.pricing-grid_list .pricing-plan {
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  padding: 30px;
  background-color: #0e0c15;
  color: var(--color-white);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.pricing-grid_list .pricing-plan ul {
  margin-bottom: 50px;
}

.pricing-grid_list .pricing-plan ul li {
  margin: 20px 0;
  padding: 0 0 0 35px;
  font-weight: 300;
  font-size: 14px;
  position: relative;
}

.pricing-grid_list .pricing-plan ul li:before {
  content: "\f058";
  font-weight: 900;
  font-family: "Font Awesome 6 Pro";
  line-height: 1;
  text-rendering: auto;
  font-size: 20px;
  position: absolute;
  left: 0;
  top: 1px;
}

.pricing-grid_list .pricing-plan .top-header h5 {
  font-size: 25px;
  font-weight: 300;
}

.pricing-grid_list .pricing-plan .top-header p {
  font-size: 14px;
  font-weight: 300;
}

.pricing-grid_list .pricing-plan .plan-price {
  font-size: 30px;
  font-weight: 300;
}

.pricing-grid_list .pricing-plan .plan-price span {
  font-size: 60px;
  font-weight: 600;
}

.pricing-grid_list .pricing-plan.monthlyAgencyPlan {
  border: 1px solid var(--color-white);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  transition: all 0.4s ease;
  color: var(--color-white);
  background-image: linear-gradient(#0e0c15, #0e0c15), linear-gradient(0deg, #FFE290, #903FD7, #FB8ADA);
  border-color: transparent;
}

.pricing-grid_list .pricing-plan.annualAgencyPlan {
  background-image: url(../public/images/annual-agency-plan-bg.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.pricing-grid_list .pricing-plan .get-it-button {
  padding-left: 35px;
  margin-top: auto;
}

.documentation-grid {
  margin: 40px 0 50px;
}

.documentation-grid .banner-home_text {
  margin-bottom: 50px;
}

.documentation-grid .banner-home_text.with-h1-line h1:after {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -8px;
}

.documentation-grid_list .group-link {
  position: sticky;
  top: 10px;
  z-index: 99;
  padding: 0 15px;
}

.documentation-grid_list .group-link ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.documentation-grid_list .group-link ul li .cta-border {
  padding: 20px;
  min-width: 100px;
  background: var(--color-010214);
}

.documentation-grid_list .group-link ul li .cta-border:hover {
  background: var(--color-hover);
  color: var(--color-black);
  border-color: transparent;
}

.documentation-grid_list .group-link ul li .cta-border.active {
  background: var(--color-hover);
  color: var(--color-black);
}

.documentation-grid_list .group-link ul li .cta-border-tab {
  /* padding: 20px;
  min-width: 100px; */
  background: var(--color-010214);
  font-size: 12px;
}

.documentation-grid_list .group-link ul li .cta-border-tab:hover {
  background: var(--color-hover);
  color: var(--color-black);
  border-color: transparent;
}

.documentation-grid_list .group-link ul li .cta-border-tab.active {
  background: var(--color-hover);
  color: var(--color-black);
}


.documentation-grid_list header {
  text-align: center;
  margin-bottom: 50px;
}

.documentation-grid_list #ChatbotIntegration,
.documentation-grid_list #FAQs,
.documentation-grid_list #tutorialsVideo {
  padding-top: 100px;
}

.documentation-grid_list .chatbot-ig ul li {
  margin: 30px 0;
}

.documentation-grid_list .chatbot-ig ul li h5 {
  font-size: 24px;
  font-weight: 700;
}

.documentation-grid_list .chatbot-ig ul li p {
  font-size: 14px;
}

.documentation-grid_list .chatbot-ig ul li p:last-child {
  margin-bottom: 0;
}

.documentation-grid_list .faqs-grid ul li {
  border-bottom: 1px solid var(--color-999999);
  padding: 25px 0 0;
}

.documentation-grid_list .faqs-grid ul li:last-child {
  border-bottom: 0;
}

.documentation-grid_list .faqs-grid ul li .acc-head {
  position: relative;
  cursor: pointer;
}

.documentation-grid_list .faqs-grid ul li .acc-head:after {
  content: "\2b";
  font-weight: 700;
  font-family: "Font Awesome 6 Pro";
  line-height: 1;
  text-rendering: auto;
  font-size: 20px;
  position: absolute;
  right: 0;
  top: 0px;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-primary);
  border: 2px solid var(--color-primary);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.documentation-grid_list .faqs-grid ul li .acc-head h5 {
  margin-bottom: 25px;
}

.documentation-grid_list .faqs-grid ul li .acc-info {
  margin-top: 25px;
}

.documentation-grid_list .faqs-grid ul li .acc-info p {
  font-size: 14px;
}

.documentation-grid_list .faqs-grid ul li.active .acc-head:after {
  content: "\f068";
}

.documentation-grid_list .tutorials-video-grid {
  margin-bottom: 50px;
}

.documentation-grid_list .tutorials-video-grid .intro-text {
  margin-bottom: 80px;
}

.documentation-grid_list .tutorials-video-grid .intro-text h5 {
  font-size: 24px;
  font-weight: 700;
}

.documentation-grid_list .tutorials-video-grid .intro-text p {
  font-size: 14px;
}

.documentation-grid_list .tutorials-video-grid ul.training-videos {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0px;
}

.documentation-grid_list .tutorials-video-grid ul.training-videos li {
  flex-basis: 20%;
  padding: 0 0 35px 35px;
  position: relative;
  flex: 0 0 20%;
  max-width: 20%;
  -ms-flex: 20%;
}

.documentation-grid_list .tutorials-video-grid ul.training-videos li:nth-child(-n+5) {
  padding: 0 35px 35px 0;
}

.documentation-grid_list .tutorials-video-grid ul.training-videos li img {
  width: 100%;
}

.documentation-grid_list .tutorials-video-grid ul.training-videos li .training-videos_block {
  position: relative;
}

.documentation-grid_list .tutorials-video-grid ul.training-videos li .play-video {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: pointer;
  font-size: 72px;
}


.contact-grid {
  margin: 40px 0 50px;
}

.contact-grid_block {
  position: relative;
  margin: 40px 0 0;
}

.contact-grid_block:before {
  content: '';
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 100px;
  z-index: -1;
  width: 240px;
  height: 240px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background-color: #37145D;
  box-shadow: 0px 0px 100px 100px #37145d;
}

.contact-grid_block .contact-info {
  margin-bottom: 30px;
}

.contact-grid_block .contact-info ul {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  padding: 0 20px;
}

.contact-grid_block .contact-info ul li {
  font-size: 24px;
}

.contact-grid_block .contact-info ul li img {
  margin-right: 10px;
}

.contact-grid_block .contact-info ul li:last-child {
  max-width: 270px;
  display: flex;
  align-items: flex-start;
}

.contact-grid_block .contact-info ul li:last-child img {
  margin-top: 5px;
}

.contact-grid_block .box-border {
  /*border: 1px solid var(--color-white);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  transition: all 0.4s ease;  
  background-image: linear-gradient(#0e0c15, #0e0c15), linear-gradient(0deg, #FFE290, #9F6DAB, #854FCB);
  border-color: transparent;*/
  color: var(--color-white);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: 1px solid #fcfcfd33;
}

.contact-grid_block .contact-block {display: flex; gap: 20px; height: 100%;}
.contact-grid_block .contact-block .contact-left {width: 40%; margin-bottom: 70px;}
.contact-grid_block .contact-block .contact-left .contact-form {padding: 30px;}
.contact-grid_block .contact-block .contact-left .contact-form .cta-dark {min-width: auto; width: 100%; max-width: 226px; padding: 20px;}
.contact-grid_block .contact-block .contact-left .contact-form .field {margin-bottom: 20px;}
.contact-grid_block .contact-block .contact-left .contact-form .field label {font-size: 14px; font-weight: 300;}
.contact-grid_block .contact-block .contact-left .contact-form .field small {
  font-size: 12px; font-weight: 300; line-height: 170%;
  display: block; margin-bottom: 15px;
}
.contact-grid_block .contact-block .contact-left .contact-form .field small a {color: #FB8ADA;}
.contact-grid_block .contact-block .contact-left .contact-form .field small a:hover {text-decoration: underline;}
.contact-grid_block .contact-block .contact-left .contact-form .file-upload-container {
  display: flex; align-items: center; gap: 20px;
  padding: 15px 30px; border-radius: 10px; border: 1px dashed var(--color-white);
}
.contact-grid_block .contact-block .contact-left .contact-form .file-upload-container .file-upload-input {display: none;}
.contact-grid_block .contact-block .contact-left .contact-form .file-upload-container .file-upload-label {margin: 0;}
.contact-grid_block .contact-block .contact-left .contact-form .file-upload-container .image-preview {max-width: 80px; height: 50px; display: flex; align-items: center;}
.contact-grid_block .contact-block .contact-left .contact-form .file-upload-container .image-preview img {max-height: 50px; object-fit: fill;}

.contact-grid_block .contact-block .contact-right {width: 55%; margin-bottom: 0px;}
.contact-grid_block .contact-block .contact-right .schedule-appointment {padding: 30px 45px 40px; height: 100%;}
.contact-grid_block .contact-block .contact-right .schedule-appointment .calendar-schedule {display: flex; gap: 50px; margin-bottom: 10px;}
.contact-grid_block .contact-block .contact-right .schedule-appointment .calendar-schedule_left {-ms-flex: 180px; flex: 0 0 180px; max-width: 180px;}
.contact-grid_block .contact-block .contact-right .schedule-appointment .calendar-schedule_left>ul>li {margin-bottom: 20px;}
.contact-grid_block .contact-block .contact-right .schedule-appointment .calendar-schedule_left>ul>li:last-child {margin-bottom: 0px;}
.contact-grid_block .contact-block .contact-right .schedule-appointment .calendar-schedule_left>ul>li img {margin-right: 10px;}
.contact-grid_block .contact-block .contact-right .schedule-appointment .calendar-schedule_left>ul>li.any-available {position: relative; padding-bottom: 10px;}
.contact-grid_block .contact-block .contact-right .schedule-appointment .calendar-schedule_left>ul>li.any-available .acc-info {
  position: absolute; top: 100%; width: 100%; z-index: 9; border-radius: 5px; padding: 10px;
  background-color: #0e0c15; border: 1px solid var(--color-262626);  
}
.contact-grid_block .contact-block .contact-right .schedule-appointment .calendar-schedule_left>ul>li.any-available .acc-info ul li {padding: 5px 0;}
.contact-grid_block .contact-block .contact-right .schedule-appointment .calendar-schedule_left>ul>li.any-available .acc-info ul li a {display: block;}
.contact-grid_block .contact-block .contact-right .schedule-appointment .calendar-schedule_left>ul>li.any-available-select {position: relative; padding-left: 20px;}
.contact-grid_block .contact-block .contact-right .schedule-appointment .calendar-schedule_left>ul>li.any-available-select:after {
  content: "\f04b"; font-weight: 700; font-family: "Font Awesome 6 Pro";
  line-height: 1; text-rendering: auto; position: absolute; right: 10px; top: 10px;
  font-size: 12px; color: var(--color-white); transform: rotateZ(90deg);
  -webkit-transform: rotateZ(90deg);  -moz-transform: rotateZ(90deg);
  -ms-transform: rotateZ(90deg); -o-transform: rotateZ(90deg);  
}

.contact-grid_block .contact-block .contact-right .schedule-appointment .calendar-schedule_left>ul>li.any-available-select img {
  position: absolute;
  left: 0;
  z-index: 1;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.contact-grid_block .contact-block .contact-right .schedule-appointment 
.calendar-schedule_left>ul>li.any-available-select .form-select {
  padding: 5px 20px 5px 16px;
  background-image: none;
  position: relative;
  background-color: #0e0c15;
  border: 0px;
}

.contact-grid_block .contact-block .contact-right .schedule-appointment 
.calendar-schedule_left>ul>li.any-available-select .form-select.form-select:focus {
  border-color: transparent !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0);
}

.contact-form .field .MuiOutlinedInput-notchedOutline{border: 1px solid #fff; border-radius: 10px;}
.contact-form .field .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{border-width: 1px; border-color: #fff;}
.contact-form .field .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {border-color: #fff;}
.contact-form .field input.MuiInputBase-input{padding: 10px 14px; color: #fff;}
.contact-form .field .MuiTypography-body1 {color: #fff;}

.calendar-schedule .calendar-schedule_left .calendar-time{display: flex;}
.calendar-schedule .calendar-schedule_left .calendar-time select{color: #fff; background: #000; border: 0;}

.time-slots-container{
  display: flex; gap: 3px; align-items: center; 
  justify-content: center; flex-wrap: wrap; margin-top: 40px;
}
.time-slots-container .time-slots-time{
  border: 1px solid #fff; border-radius: 3px; 
  padding: 3px 5px; font-size: 15px; cursor: pointer;
}

.contact-grid_block .contact-block .contact-right .schedule-appointment .calendar-schedule_right {
  flex: 1 1 auto;
}

.contact-grid_block .contact-block .contact-right .schedule-appointment .calendar-block {
  position: relative;
}

.contact-grid_block .contact-block .contact-right .schedule-appointment .time-zone {
  text-align: center;
  padding-top: 40px;
  margin-top: 40px;
  border-top: 1px solid #565656;
}

.contact-grid_block .contact-block .contact-right .schedule-appointment .time-zone h5 {
  font-size: 24px;
}

.contact-grid_block .contact-block .contact-right .schedule-appointment .time-zone_block {
  position: relative;
  max-width: 420px;
  width: 100%;
  margin: auto;
}

.contact-grid_block .contact-block .contact-right .schedule-appointment .time-zone_block .fa-globe {
  position: absolute;
  z-index: 2;
  left: 20px;
  font-size: 20px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: var(--color-black);
}

.contact-grid_block .contact-block .contact-right .schedule-appointment .time-zone_block .form-select {
  color: var(--color-black);
  background-color: var(--color-white);
  border: 1px solid var(--color-1F1A24);
  padding: 10px 30px 10px 50px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  font-weight: 300;
}
.contact-grid_block .contact-block .contact-right .schedule-appointment .time-zone_block .form-select option{
  color: var(--color-black);
  background-color: var(--color-white);  
}

.contact-grid_block .contact-block iframe{height: 1030px !important;}
.contact-grid_block .contact-block .or-text{
  display: flex; align-items: center; justify-content: center;
  font-size: 24px; font-weight: 700; width: 5%;
}
@media screen and (min-width: 1400px) {
  .contact-grid_block .contact-block iframe{margin-top: -1rem !important;}
  .contact-grid_block .contact-block .contact-left .contact-form {height: 100%;}
}


.calendar-container .react-calendar__navigation__arrow,
.calendar-container .react-calendar__navigation__label,
.calendar-container .react-calendar .react-calendar__month-view__days__day{color: #fff;}

.calendar-container .react-calendar{width: 100%; background: #000000; border: 0;}
.calendar-container .react-calendar__month-view__days__day--neighboringMonth{color: #979797 !important;}

.calendar-container .react-calendar__tile{padding: 0px;}

.calendar-container .react-calendar__tile:enabled:hover, 
.calendar-container .react-calendar__tile:enabled:focus,
.calendar-container .react-calendar__tile--now,
.calendar-container .react-calendar__tile--active:enabled:hover, 
.calendar-container .react-calendar__tile--active:enabled:focus{background: transparent;} 

.calendar-container .react-calendar__tile abbr{
  width: 32px; height: 32px;  display: flex; align-items: center;
  justify-content: center; margin: auto;  
}

.calendar-container .react-calendar__month-view abbr[title] {font-weight: 400; text-transform: capitalize;}

.calendar-container .react-calendar__tile--active{background: transparent; color: #000 !important;}
.calendar-container .react-calendar__tile--active:enabled:hover{background: transparent;}

.calendar-container .react-calendar__tile:enabled:hover abbr,
.calendar-container .react-calendar__tile--active abbr{
  background: #75ff37; color: #000 !important; width: 32px; height: 32px;
  border-radius: 32px; display: flex; align-items: center;
  justify-content: center; margin: auto;
}

.calendar-container .react-calendar__navigation button:enabled:hover, 
.calendar-container .react-calendar__navigation button:enabled:focus {
  background: #75ff37; color: #000;
}
.calendar-container .book-call{}

body {
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: var(--body-text);
  background-color: #010314;
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

@media screen and (min-width: 992px) {
  body {
    font-size: 16px;
  }
}

body:before {
  content: '';
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 300px;
  background: #030A28;
  /* Old browsers */
  background: linear-gradient(0deg, #030A28 50%, #010314 100%);
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(bottom, #030A28 50%, #010314 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(bottom, #030A28 50%, #010314 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(bottom, #030A28 50%, #010314 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #030A28 50%, #010314 100%);
  /* W3C */
}

#window {
  width: 100%;
  min-height: 100%;
  transition: transform 0.4s ease-in-out;
  -ms-transition: transform 0.4s ease-in-out;
  -o-transition: transform 0.4s ease-in-out;
  -webkit-transition: transform 0.4s ease-in-out;
  -moz-transition: transform 0.4s ease-in-out;
}

#window:after {
  content: "";
  display: table;
  clear: both;
}

.body-wrap {
  min-height: 300px;
  width: 100%;
  margin: 0 auto;
}

.body-wrap:after {
  content: "";
  display: table;
  clear: both;
}

p {
  line-height: 1.85;
  margin-bottom: 20px;
}

@media screen and (min-width: 992px) {
  p {
    margin-bottom: 25px;
  }
}

a {
  text-decoration: none;
  color: var(--color-ffffff);
}

a:hover {
  text-decoration: none;
  color: var(--color-hover);
}

hr {
  margin-top: 16px;
  margin-bottom: 16px;
  border: 0;
  opacity: 1;
  border-top: 1px solid var(--color-border);
}

img,
svg {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

figure {
  display: block;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
}

figure:after {
  content: "";
  display: table;
  clear: both;
}

a:focus,
:focus-visible {
  outline: none;
}

ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

ul li {
  padding: 0px;
  margin: 0px;
}

.none {
  display: none;
}

.hidescroll {
  overflow: hidden;
}

.container {
  max-width: 1424px;
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  margin: 0 auto;
  position: relative;
}

.container:after {
  content: "";
  display: table;
  clear: both;
}

.container.full {
  max-width: 100%;
  padding-left: 12px;
  padding-right: 12px;
}

.container.larg {
  max-width: 1624px;
}

.container.med {
  max-width: 1284px;
}

.container.sum-med {
  max-width: 1224px;
}

.gbl {
  margin-top: 50px;
  margin-bottom: 50px;
}

.gbl-pad {
  padding-top: 60px;
  padding-bottom: 60px;
}

.gbl-60 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.gbl-top-30 {
  margin-top: 30px;
}

.gbl-top-40 {
  margin-top: 40px;
}

.gbl-top-50 {
  margin-top: 40px;
}

.gbl-top-60 {
  margin-top: 60px;
}

.gbl-top-70 {
  margin-top: 70px;
}

.gbl-top-80 {
  margin-top: 80px;
}

.gbl-bottom-30 {
  margin-bottom: 30px;
}

.gbl-bottom-40 {
  margin-bottom: 40px;
}

.gbl-bottom-50 {
  margin-bottom: 40px;
}

.gbl-bottom-60 {
  margin-bottom: 60px;
}

.gbl-bottom-70 {
  margin-bottom: 70px;
}

.gbl-bottom-80 {
  margin-bottom: 80px;
}

@media screen and (min-width: 992px) {
  .gbl {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .gbl-pad {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .gbl-100 {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-1e1e1f);
  font-weight: 400;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  position: relative;
  line-height: normal;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

h1 {
  font-size: 69px;
  font-weight: 46px;
  color: var(--color-white);
  font-weight: 600;
}

h2 {
  font-size: 36px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

@media screen and (min-width: 992px) {
  h1 {
    font-size: 69px;
    line-height: 75px;
  }

  h2 {
    font-size: 62px;
    line-height: 83px;
    margin-bottom: 20px;
    font-weight: 500;
  }

  h3 {
    font-size: 52px;
    line-height: 130%;
    margin-bottom: 20px;
    font-weight: 300;
  }

  h4 {
    font-size: 30px;
    line-height: 100%;
  }

  h5 {
    font-size: 20px;
  }
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-12 {
  font-size: 12px;
}

.for-mobile {
  display: none;
}

@media screen and (max-width: 992px) {
  .for-desktop {
    display: none !important;
  }

  .for-mobile {
    display: block;
  }
}

img.cover,
video.cover {
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
}

@media (min-width: 768px) {
  .modal-small {
    max-width: 450px;
    width: 100%;
  }

  .modal-sm {
    max-width: 650px;
    width: 100%;
  }

  .modal-md {
    max-width: 850px;
    width: 100%;
  }

  .modal-lg {
    max-width: 1050px;
    width: 100%;
  }
}

*.mt--0 {
  margin-top: 0;
}

*.mt--10 {
  margin-top: 10px;
}

*.mt--20 {
  margin-top: 20px;
}

*.mt--30 {
  margin-top: 30px;
}

*.mt--40 {
  margin-top: 40px;
}

*.mt--50 {
  margin-top: 50px;
}

*.mt--60 {
  margin-top: 60px;
}

*.mt--70 {
  margin-top: 70px;
}

*.mt--80 {
  margin-top: 80px;
}

*.mt--90 {
  margin-top: 90px;
}

*.mt--100 {
  margin-top: 100px;
}

*.mb--0 {
  margin-bottom: 0 !important;
}

*.mb--10 {
  margin-bottom: 10px;
}

*.mb--20 {
  margin-bottom: 20px;
}

*.mb--30 {
  margin-bottom: 30px;
}

*.mb--40 {
  margin-bottom: 40px;
}

*.mb--50 {
  margin-bottom: 50px;
}

*.mb--60 {
  margin-bottom: 60px;
}

*.mb--70 {
  margin-bottom: 70px;
}

*.mb--80 {
  margin-bottom: 80px;
}

*.mb--90 {
  margin-bottom: 90px;
}

*.mb--100 {
  margin-bottom: 100px;
}

*.pt--0 {
  padding-top: 0;
}

*.pt--10 {
  padding-top: 10px;
}

*.pt--20 {
  padding-top: 20px;
}

*.pt--30 {
  padding-top: 30px;
}

*.pt--40 {
  padding-top: 40px;
}

*.pt--50 {
  padding-top: 50px;
}

*.pt--60 {
  padding-top: 60px;
}

*.pt--70 {
  padding-top: 70px;
}

*.pt--80 {
  padding-top: 80px;
}

*.pt--90 {
  padding-top: 90px;
}

*.pt--100 {
  padding-top: 100px;
}

*.pb--0 {
  padding-bottom: 0 !important;
}

*.pb--10 {
  padding-bottom: 10px;
}

*.pb--20 {
  padding-bottom: 20px;
}

*.pb--30 {
  padding-bottom: 30px;
}

*.pb--40 {
  padding-bottom: 40px;
}

*.pb--50 {
  padding-bottom: 50px;
}

*.pb--60 {
  padding-bottom: 60px;
}

*.pb--70 {
  padding-bottom: 70px;
}

*.pb--80 {
  padding-bottom: 80px;
}

*.pb--90 {
  padding-bottom: 90px;
}

*.pb--100 {
  padding-bottom: 100px;
}

.gbl-header nav.site-nav {
  width: auto;
}

.gbl-header nav.site-nav .site-menu {
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0 50px;
  justify-content: center;
}

.gbl-header nav.site-nav .site-menu>li {
  text-transform: uppercase;
}

.gbl-header nav.site-nav .site-menu .sub-menu {
  font-size: 18px;
}

.gbl-header nav.site-nav .site-menu .sub-menu>a {
  color: var(--color-101010);
  padding: 15px 0;
  display: block;
  font-weight: 500;
}

.gbl-header nav.site-nav .site-menu .sub-menu>a .fa-chevron-down {
  -webkit-transition: 0.4s ease-out;
  -moz-transition: 0.4s ease-out;
  transition: 0.4s ease-out;
  font-size: 14px;
}

.gbl-header nav.site-nav .site-menu .sub-menu>a:hover {
  color: var(--color-primary);
}

.gbl-header nav.site-nav .site-menu .sub-menu:not(.has-mega-menu) {
  position: relative;
}

.gbl-header nav.site-nav .site-menu .sub-menu:not(.has-mega-menu)>.dropdow-menu {
  left: 50%;
  max-width: 250px;
  min-width: 250px;
  padding: 20px 0 0;
}

.gbl-header nav.site-nav .site-menu .sub-menu:last-child .dropdow-menu {
  transform: translate(-85%, 20px);
}

.gbl-header nav.site-nav .site-menu .sub-menu:last-child:hover .dropdow-menu {
  transform: translate(-85%, 0px);
}

.gbl-header nav.site-nav .site-menu .sub-menu:hover>a {
  color: var(--color-primary);
}

.gbl-header nav.site-nav .site-menu .sub-menu:hover .dropdow-menu {
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, 0px);
}

.gbl-header nav.site-nav .site-menu .sub-menu:hover .fa-chevron-down {
  -webkit-transform: rotateZ(-182deg);
  -moz-transform: rotateZ(-182deg);
  -ms-transform: rotateZ(-182deg);
  -o-transform: rotateZ(-182deg);
  transform: rotateZ(-182deg);
}

.gbl-header nav.site-nav .site-menu .sub-menu.hover .dropdow-menu {
  display: block;
  transition: transform 5s ease;
  transform: translate(-50%, 0px);
}

.gbl-header nav.site-nav .dropdow-menu {
  left: 50%;
  position: absolute;
  z-index: 3;
  padding: 40px 0 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: transform .5s ease, opacity .5s ease;
  transform: translate(-50%, 20px);
}

.gbl-header nav.site-nav .dropdow-menu .container {
  display: block;
  max-width: 1300px;
  padding: 30px 15px;
}

.gbl-header nav.site-nav .dropdow-menu .inner {
  max-width: 100%;
  padding: 5px;
  margin: 0 auto;
  list-style: none;
  background-color: var(--color-secondary);
  box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.3);
}

.gbl-header nav.site-nav .dropdow-menu .inner>ul {
  padding: 0px;
  margin: 0px;
}

.gbl-header nav.site-nav .dropdow-menu .inner>ul li {
  list-style: none;
  font-size: 16px;
  line-height: 18px;
  margin: 4px 0;
}

.gbl-header nav.site-nav .dropdow-menu .inner>ul li>a {
  background-color: var(--color-secondary);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 10px 15px;
  display: block;
  margin: 0px;
  color: var(--color-white);
}

.gbl-header nav.site-nav .dropdow-menu .inner>ul li>a:hover {
  color: var(--color-101010);
  background-color: rgba(255, 255, 255, 0.9);
}

@media screen and (max-width: 1350px) {
  .gbl-header nav.site-nav .site-menu {
    gap: 0 30px;
  }
}
@media screen and (max-width: 1200px) {
  .gbl-header nav.site-nav .site-menu {
    gap: 0 15px;
  }
  .gbl-header nav.site-nav .site-menu li{
    font-size: 14px;
  }
}

@media screen and (max-width: 1024px) {
  .gbl-header nav.site-nav {
    display: none;
  }

  .gbl-header .navToggle {
    display: block;
    position: absolute;
    right: 15px;
    top: 50%; margin-top: -10px;
    background-color: transparent;
  }
}

/* For Mobile Menu */
.navToggle {
  margin-left: auto;
  display: none;
  padding: 0px;
  margin: 0px;
  border: 0px;
  outline: none;
  background: transparent;
}

.mobnav-close img {
  -webkit-transition: 0.4s ease-out;
  -moz-transition: 0.4s ease-out;
  transition: 0.4s ease-out;
}

.mobnav-close img:hover {
  -webkit-transform: rotateZ(182deg);
  -moz-transform: rotateZ(182deg);
  transform: rotateZ(182deg);
}

.hamburger-menu {
  margin: auto;
  width: 32px;
  height: 21px;
  cursor: pointer;
  left: 0px;
  position: relative;
  display: block;
}

.hamburger-menu .bar,
.hamburger-menu .bar:after,
.hamburger-menu .bar:before {
  height: 3px;
}

.hamburger-menu .bar {
  position: relative;
  transform: translateY(8px);
  background: var(--color-secondary);
  transition: all 0ms 300ms;
  width: 30px;
  display: block;
}

.hamburger-menu .bar::before {
  width: 30px;
  content: "";
  position: absolute;
  right: 0;
  bottom: 8px;
  background: var(--color-secondary);
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu .bar::after {
  width: 30px;
  content: "";
  position: absolute;
  right: 0;
  top: 8px;
  background: var(--color-secondary);
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.mobileMenuOpen .hamburger-menu .bar {
  background: transparent;
}

.mobileMenuOpen .hamburger-menu .bar::after {
  top: 0;
  transform: rotate(45deg);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.mobileMenuOpen .hamburger-menu .bar::before {
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.mobnav-grid {
  background-color: #040b28;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#040b28), to(#010214));
  background-image: -webkit-linear-gradient(top, #040b28, #010214);
  background-image: -moz-linear-gradient(top, #040b28, #010214);
  background-image: -ms-linear-gradient(top, #040b28, #010214);
  background-image: -o-linear-gradient(top, #040b28, #010214);
  background-image: linear-gradient(top, #040b28, #010214);
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 50px 15px 100px;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 100;
  overflow: auto;
  max-width: 400px;
  width: 100%;
  transition: 0.5s;
  transform: translateX(100%);
}

.mobnav-grid .mobnav-close {
  margin-bottom: 10px;
  top: 20px;
  margin-top: 0px;
}

.mobnav-grid.open-mobile-nav {
  transform: translateX(0);
  box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.4);
}

.mobnav-grid ul {
  width: 100%;
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.mobnav-grid ul li {
  font-size: 16px;
  line-height: 1.4;
  margin: 0;
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.mobnav-grid ul li:last-child {
  border-bottom: 0px;
}

.mobnav-grid ul li>a {
  color: var(--color-white);
  display: block;
  padding: 10px 0;
  font-weight: 500;
}

.mobnav-grid ul li>a:hover {
  color: var(--color-white);
}

.mobnav-grid ul li .menu-link:before {
  position: absolute;
  right: 0;
  top: 12px;
  color: var(--color-white);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 22px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f107";
}

.mobnav-grid ul li.active .menu-link:before {
  content: "\f106";
}

.mobnav-grid ul li .menu-info li {
  font-size: 16px;
  margin: 0px !important;
}

.mobnav-grid ul li .menu-info li>a {
  padding: 5px 10px;
  font-weight: 400;
}

.mobnav-grid ul li .menu-info li>a:hover {
  color: var(--color-101010);
}

.mobnav-grid ul li ul li {
  border-bottom: 0px;
}

[class*=cta-] {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  position: relative;
  border: 0px;
  outline: none;
  cursor: pointer;
  padding: 12px 20px;
  line-height: 1.2;
  letter-spacing: 0.5px;
  white-space: nowrap;
  font-weight: 400;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  font-size: 14px;
}

[class*=cta-].small {
  font-weight: 400;
}

[class*=cta-] .fa-solid,
[class*=cta-] img {
  margin-right: 5px;
}

.cta-green-with-text {  
  cursor: auto !important;
}

.cta-green-with-text,
.cta-green {
  background: var(--color-secondary);
  color: var(--color-black);
  transition: opacity 0.3s;
  text-transform: uppercase;
}

.cta-green:hover {
  background: #ECB262;
  /* Old browsers */
  background: linear-gradient(90deg, #ECB262 0%, #C285AC 35%, #903FD7 60%, #9F5FEA 100%);
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, #ECB262 0%, #C285AC 35%, #903FD7 60%, #9F5FEA 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, #ECB262 0%, #C285AC 35%, #903FD7 60%, #9F5FEA 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(left, #ECB262 0%, #C285AC 35%, #903FD7 60%, #9F5FEA 100%);
  /* IE10+ */
  background: linear-gradient(to right, #ECB262 0%, #C285AC 35%, #903FD7 60%, #9F5FEA 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$color1', endColorstr='$color2', GradientType=1);
  /* IE6-9 */
  color: var(--color-white);
}
.cta-purple-with-text {
  background: #903fd7;
  color: var(--color-white);
  transition: opacity 0.3s;
  text-transform: uppercase;
  cursor: auto !important;
}
.cta-white {
  background: var(--color-white);
  color: var(--color-black);
  transition: opacity 0.3s;
}

.cta-white:hover {
  background: #ECB262;
  /* Old browsers */
  background: linear-gradient(90deg, #ECB262 0%, #C285AC 35%, #903FD7 60%, #9F5FEA 100%);
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, #ECB262 0%, #C285AC 35%, #903FD7 60%, #9F5FEA 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, #ECB262 0%, #C285AC 35%, #903FD7 60%, #9F5FEA 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(left, #ECB262 0%, #C285AC 35%, #903FD7 60%, #9F5FEA 100%);
  /* IE10+ */
  background: linear-gradient(to right, #ECB262 0%, #C285AC 35%, #903FD7 60%, #9F5FEA 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$color1', endColorstr='$color2', GradientType=1);
  /* IE6-9 */
  color: var(--color-white);
}

.cta-light {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid var(--color-white);
  min-width: 112px;
}

.cta-light:hover {
  background: var(--color-hover);
  color: var(--color-black);
  border-color: transparent;
}

.cta-dark {
  border: 1px solid var(--color-white);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  transition: all 0.4s ease;
  color: var(--color-white);
  background-image: linear-gradient(#030a27, #030a27), linear-gradient(90deg, #FFE290, #9F6DAB, #854FCB);
  border-color: transparent;
}

.cta-dark:hover {
  background: var(--color-hover);
  color: var(--color-black);
  border-color: transparent;
}

.cta-gradient {
  background: #ECB262;
  /* Old browsers */
  background: linear-gradient(90deg, #ECB262 0%, #C285AC 35%, #903FD7 60%, #9F5FEA 100%);
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, #ECB262 0%, #C285AC 35%, #903FD7 60%, #9F5FEA 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, #ECB262 0%, #C285AC 35%, #903FD7 60%, #9F5FEA 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(left, #ECB262 0%, #C285AC 35%, #903FD7 60%, #9F5FEA 100%);
  /* IE10+ */
  background: linear-gradient(to right, #ECB262 0%, #C285AC 35%, #903FD7 60%, #9F5FEA 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$color1', endColorstr='$color2', GradientType=1);
  /* IE6-9 */
  color: var(--color-white);
  border: 1px solid var(--color-white);
  min-width: 112px;
}

.cta-gradient:hover {
  background: var(--color-hover);
  color: var(--color-black);
  border-color: transparent;
}

.cta-border {
  padding: 10px;
  border: 1px solid var(--color-white);
  color: var(--color-ffffff);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  transition: all 0.4s ease;
}

.cta-border:hover {
  color: var(--color-white);
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  box-shadow: 0 0 9px 0 #854FCB;
  background-image: linear-gradient(#030a27, #030a27), linear-gradient(90deg, #FFE290, #9F6DAB, #854FCB);
  border-color: transparent;
}

.cta-border-tab {
  padding: 10px;
  border: 1px solid var(--color-white);
  color: var(--color-ffffff);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  transition: all 0.4s ease;
}

.cta-border-tab:hover {
  color: var(--color-white);
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  box-shadow: 0 0 9px 0 #854FCB;
  background-image: linear-gradient(#030a27, #030a27), linear-gradient(90deg, #FFE290, #9F6DAB, #854FCB);
  border-color: transparent;
}

.text-link {
  color: var(--color-primary);
}

.text-link:hover {
  text-decoration: underline;
}

.social-media:after {
  content: "";
  display: table;
  clear: both;
}

.social-media ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.social-media ul li a {
  display: block;
  text-align: center;
  position: relative;
  background-color: var(--color-primary);
  color: var(--color-black);
  width: 26px;
  height: 26px;
  line-height: 26px;
  -webkit-border-radius: 150%;
  -moz-border-radius: 150%;
  border-radius: 150%;
  transition: all 0.4s ease;
}

.social-media ul li a .fab,
.social-media ul li a .fa-brands {
  font-size: 14px;
}

.social-media ul li a:hover {
  background-color: var(--color-white);
  color: var(--color-black);
}

.checkbox-list input[type=file]:focus,
.checkbox-list input[type=checkbox]:focus,
.checkbox-list input[type=radio]:focus {
  outline: none;
  outline-offset: 0;
}

.form-control::-webkit-input-placeholder {
  color: var(--color-999999);
}

.form-control::-moz-placeholder {
  color: var(--color-999999);
}

.form-control::-ms-placeholder {
  color: var(--color-999999);
}

.form-control::placeholder {
  color: var(--color-999999);
}

.field {
  margin: 0 0 20px;
}

.field label {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  color: var(--color-white);
  font-size: 12px;
  font-family: "Poppins", sans-serif;
}

.field .error {
  border-color: var(--color-ff0800);
}

.field .error+.error {
  color: var(--color-ff0800);
  font-weight: 400;
  border-color: transparent;
  font-size: 14px;
  margin: 5px 0 0;
}

.form-control,
.form-select {
  display: block;
  width: 100%;
  padding: 10px 15px;
  line-height: 1.5;
  /* color: var(--color-text);
  background-color: var(--color-1F1A24);
  border: 1px solid var(--color-white); */
  border-radius: 10px;
  outline: none;
  font-size: 14px;
}

.form-control:focus,
.form-select:focus {
  outline: none;
  background-color: var(--color-1F1A24);
  color: var(--color-text);
}

/* Checkbox Css Style */
.form-check,
.form-radio {
  position: relative;
  padding-left: 43px;
  font-weight: 300;
  width: 100%;
  display: block;
  font-size: 16px;
}

.form-check .error,
.form-radio .error {
  border-color: var(--red-color);
}

.form-check .error+label,
.form-radio .error+label {
  display: none !important;
}

.form-check label,
.form-radio label {
  cursor: pointer;
}

.form-check .checkbox,
.form-radio .checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: absolute;
  top: 3px;
  right: 0;
  bottom: 0;
  left: 12px;
  z-index: 9;
  height: 16px;
  width: 16px;
  transition: all 0.15s ease-out 0s;
  display: inline-block;
  outline: none;
  background: var(--color-010214);
  border: 1px solid var(--color-white);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.form-check .checkbox:checked,
.form-radio .checkbox:checked {
  background: var(--color-white);
  border: 1px solid var(--color-white);
}

.form-check .checkbox:checked:before,
.form-radio .checkbox:checked:before {
  content: '✔';
  height: 16px;
  width: 16px;
  line-height: 16px;
  position: absolute;
  display: inline-block;
  font-size: 12px;
  text-align: center;
  color: var(--white-color);
  display: none;
}

.form-radio .radiobox {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: absolute;
  top: 4px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  transition: all 0.15s ease-out 0s;
  display: inline-block;
  outline: none;
  cursor: pointer;
  border: 1px solid var(--color-57B3B5);
}

.form-radio .radiobox:checked:before {
  content: '';
  height: 10px;
  width: 10px;
  display: inline-block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-color: 1px solid var(--color-57B3B5);
  background-color: var(--color-57B3B5);
}

/* End Css */
textarea.form-control {
  height: auto;
}

.errorMessage,
.successMessage {
  padding: 0 0 0 36px;
  background-color: transparent;
  border-color: transparent;
  position: relative;
  display: inline-block;
  line-height: 1.2;
  font-weight: 300;
  min-height: 24px;
  margin-top: 20px;
  font-size: 14px;
}

.errorMessage:before,
.successMessage:before {
  display: inline-block;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  font-size: 16px;
  position: absolute;
  left: 12px;
  top: 0px;
}

.errorMessage {
  color: #f70017;
}

.errorMessage:before {
  content: "\f071";
}

.successMessage {
  color: #00b80f;
}

.successMessage:before {
  content: "\f164";
}

@media screen and (min-width: 768px) {
  .field {
    margin: 0 0 25px;
  }
}

.gbl-header {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0 30px;
}

.gbl-header .brand-logo {
  max-width: 220px;
  display: block;
  margin-right: 10px;
}

.gbl-header .brand-logo img {
  display: block;
}

.gbl-header .menu-grid {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
}

.gbl-header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.gbl-header .container::after {
  content: none;
  display: inherit;
  clear: inherit;
}

.gbl-header .group-user-cta {
  display: flex;
  gap: 16px;
}

@media screen and (max-width: 1024px) {
  .gbl-header .group-user-cta {
    margin-right: 50px;
    gap: 10px;
  }

  .gbl-header .brand-logo {
    max-width: 160px;
  }
}

.banner-home {
  position: relative;
  z-index: 1;
  padding: 0px 0;
}

.banner-home_text h1 {
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: linear-gradient(to right, #A1A3A8 0%, #FFFFFF 50%, #9B9BA2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--color-white);
}

.banner-home_text.with-h1-line h1 {
  display: inline-block;
  position: relative;
}

.banner-home_text.with-h1-line h1:after {
  content: '';
  position: absolute;
  right: 0;
  bottom: -8px;
  z-index: 0;
  width: 400px;
  height: 10px;
  background: url("../public/images/connector/header-line.svg") no-repeat center;
  background-size: 100%;
}

.banner-home_text h2 {
  font-size: 42px;
  line-height: 60px;
  font-weight: 700;
  text-transform: uppercase;
  background: linear-gradient(to right, #A1A3A8 0%, #FFFFFF 50%, #9B9BA2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--color-white);
  max-width: 1500px;
}

.subscriber {
  position: relative;
  padding: 0px 15px;
}

.subscriber_block {
  max-width: 775px;
  width: 100%;
  padding: 30px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-black);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  background-color: var(--color-primary);
}

.subscriber_block .subscriber-text {
  font-weight: 700;
  font-size: 26px;
  -ms-flex: 240px;
  flex: 0 0 240px;
  max-width: 240px;
}

.subscriber_block .subscriber-text img {
  margin-right: 25px;
}

.subscriber_block .counter-item {
  flex: 1 1 auto;
  padding-left: 0px;
}

.subscriber_block .counter-item .counter {
  font-size: 56px;
  font-weight: 200;
  letter-spacing: 25px;
  line-height: 44px;
}

.freetrial-grid {
  position: relative;
}

.freetrial-grid.upper {
  margin-top: -40px;
  z-index: 0;
}

.freetrial-grid_gredient {
  background-color: #020823;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#020823), to(#00282D));
  background-image: -webkit-linear-gradient(top, #020823, #00282D);
  background-image: -moz-linear-gradient(top, #020823, #00282D);
  background-image: -ms-linear-gradient(top, #020823, #00282D);
  background-image: -o-linear-gradient(top, #020823, #00282D);
  background-image: linear-gradient(top, #020823, #00282D);
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  position: relative;
  padding: 60px 30px 0px;
  min-height: 300px;
}

.freetrial-grid_gredient:before {
  content: '';
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 20%;
  z-index: 0;
  width: 240px;
  height: 240px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background-color: #37145D;
  box-shadow: 0px 0px 100px 100px #37145d;
}

.freetrial-grid_header {
  text-align: center;
}

.freetrial-grid_image {
  margin-top: 30px;
  text-align: center;
  position: relative;
  z-index: 1;
}

.freetrial-grid_image img {
  margin-bottom: -10px;
}


.feature-list_grid {
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature-list_block {
  max-width: 240px;
  margin: 25px 0;
  width: 100%;
}

.feature-list_block figure {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 180px;
  padding: 20px;
  margin: 0 auto 25px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid var(--color-white);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-image: linear-gradient(#030a27, #030a27), linear-gradient(180deg, #FFE290, #9F6DAB, #854FCB);
  border-color: transparent;
  box-shadow: 0 0 30px 0 #51228f;
}

.feature-list_block h5 {
  font-size: 22px;
}

.feature-list_connector {
  margin: 0px -28px;
  position: relative;
  z-index: 1;
  top: -50px;
}

.chatbot-features {
  position: relative;
}

.chatbot-features .custom-ai-solutions {
  display: flex;
  align-items: normal;
}

.chatbot-features .custom-ai-solutions figure {
  flex: 1 1 auto;
}

.chatbot-features .custom-ai-solutions figure img {
  z-index: 1;
  position: relative;
}

.chatbot-features .custom-ai-solutions figure:after {
  content: '';
  position: absolute;
  bottom: 75px;
  right: 0;
  z-index: 0;
  width: 314px;
  height: 55px;
  display: inline-flex;
  background: url(../public/images/connector/customizable-ai-connector.png) 0 0 no-repeat;
}

.chatbot-features .custom-ai-solutions article {
  flex: 0 0 41%;
  max-width: 500px;
  -ms-flex: 500px;
}

.chatbot-features .custom-ai-solutions article .button-grid {
  position: relative;
}

.chatbot-features .custom-ai-solutions_connector {
  margin: 0px;
  position: relative;
  width: 310px;
  margin-left: -35px;
  z-index: 0;
  top: 0px;
}

.chatbot-features .custom-ai-solutions_connector img {
  position: absolute;
  bottom: 77px;
  left: 0;
}

.chatbot-features .seamless-integration {
  display: flex;
  align-items: normal;
  flex-direction: row-reverse;
}

.chatbot-features .seamless-integration figure {
  flex: 0 0 48%;
  max-width: 570px;
  -ms-flex: 570px;
}

.chatbot-features .seamless-integration figure img {
  z-index: 1;
  position: relative;
}

.chatbot-features .seamless-integration article {
  flex: 1 1 auto;
  position: relative;
}

.chatbot-features .seamless-integration article h3 {
  max-width: 420px;
}

.chatbot-features .seamless-integration article p {
  max-width: 420px;
  min-height: 89px;
  margin-bottom: 15px;
}

.chatbot-features .seamless-integration article .button-grid {
  position: relative;
}

.chatbot-features .seamless-integration article .button-grid:after {
  content: '';
  position: absolute;
  bottom: -99px;
  right: 0;
  z-index: -1;
  width: 535px;
  height: 122px;
  display: inline-flex;
  background: url(../public/images/connector/seamless-integration-connector.png) 0 0 no-repeat;
}

.chatbot-features .subscriber-dashboard {
  display: flex;
  align-items: normal;
}

.chatbot-features .subscriber-dashboard figure {
  flex: 0 0 50%;
  max-width: 600px;
  -ms-flex: 600px;
  padding-right: 20px;
}

.chatbot-features .subscriber-dashboard figure img {
  z-index: 1;
  position: relative;
}

.chatbot-features .subscriber-dashboard article {
  flex: 1 1 auto;
  position: relative;
  padding-top: 60px;
}

.chatbot-features .subscriber-dashboard article .button-grid {
  position: relative;
}

.chatbot-features .subscriber-dashboard article .button-grid:after {
  content: '';
  position: absolute;
  top: 18px;
  right: 100%;
  z-index: 1;
  width: 155px;
  height: 70px;
  display: inline-flex;
  background: url(../public/images/connector/user-frendly-connector.png) 0 0 no-repeat;
}

.chatbot-features .industry-specific {
  display: flex;
  align-items: normal;
  flex-direction: row-reverse;
}

.chatbot-features .industry-specific figure {
  flex: 0 0 48%;
  max-width: 570px;
  -ms-flex: 570px;
  overflow: inherit;
}

.chatbot-features .industry-specific figure img {
  z-index: 1;
  position: relative;
  margin-left: -30px;
}

.chatbot-features .industry-specific article {
  flex: 1 1 auto;
  position: relative;
  padding-top: 100px;
}

.chatbot-features .industry-specific article h3 {
  max-width: 470px;
}

.chatbot-features .industry-specific article p {
  max-width: 470px;
}

.chatbot-features .industry-specific article .button-grid {
  position: relative;
}

.chatbot-features .industry-specific article .button-grid:after {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 125px;
  z-index: -1;
  width: 615px;
  height: 20px;
  display: inline-flex;
  background: url(../public/images/connector/industry-specific-connector.png) 0 0 no-repeat;
}

.group-img-grid {
  position: relative;
}

.group-img-grid_block {
  position: relative;
  min-height: 600px;
}

.group-img-grid_block .img-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0px;
}

.group-img-grid_block .img-list li {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 33.333%;
  padding: 0 0 15px 15px;
}

.group-img-grid_block .img-list li:nth-child(-n+4) {
  padding: 0 15px 15px 0;
}

.group-img-grid_block .img-list li img {
  width: 100%;
}

.group-img-grid .ctaBlock {
  background-color: rgba(0, 0, 0, 0.73);
  position: absolute;
  left: 0;
  right: 0;
  top: 10%;
  z-index: 1;
  padding: 30px;
  min-height: 200px;
  text-align: center;
}

.group-img-grid .ctaBlock.with-h2-line header h2:first-child {
  display: inline-block;
  position: relative;
}

.group-img-grid .ctaBlock.with-h2-line header h2:first-child:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -8px;
  z-index: 0;
  width: 204px;
  height: 10px;
  background: url("../public/images/connector/header-line.svg") no-repeat center;
}

.group-img-grid .ctaBlock.with-h2-line header h2 {
  margin-bottom: 0;
}

.group-img-grid .ctaBlock.with-h2-line header h2 span {
  color: var(--color-primary);
}

.group-img-grid .ctaBlock.with-h2-line header h5 {
  font-size: 24px;
  font-weight: 600;
}

.group-img-grid .ctaBlock .short-info [class*=cta-] {
  margin: 0 5px;
}

@media screen and (min-width: 768px) {
  .group-img-grid_block .img-list {
    margin: 0;
  }

  .group-img-grid_block .img-list li {
    flex-basis: 25%;
    padding: 0 0 0px 35px;
  }

  .group-img-grid_block .img-list li:nth-child(-n+4) {
    padding: 0 35px 35px 0;
  }

  .group-img-grid .ctaBlock {
    top: 15%;
    padding: 40px 11%;
  }
}

@media screen and (min-width: 1280px) {
  .group-img-grid_block .img-list {
    margin: 0;
  }

  .group-img-grid_block .img-list li {
    flex-basis: 20%;
    padding: 0 0 0 35px;
  }

  .group-img-grid_block .img-list li:nth-child(-n+5) {
    padding: 0 35px 35px 0;
  }
}

@media screen and (max-width: 500px) {
  .group-img-grid .ctaBlock .short-info p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }
}

.logos-grid {
  position: relative;
}

.logos-grid ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -8px;
}

.logos-grid ul li {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 50%;
  padding: 0 8px;
}

.logos-grid ul li img {
  width: 100%;
  margin: 5px 0;
}

@media screen and (min-width: 768px) {
  .logos-grid ul {
    margin: 0;
  }

  .logos-grid ul li {
    flex-basis: 25%;
  }
}

.chatbot-robot {
  position: relative;
  margin-top: 100px;
}

.chatbot-robot.gradient {
  background: #401769;
  /* Old browsers */
  background: linear-gradient(0deg, #401769 0%, #0A061F 35%, #020414 60%, #1B4707 100%);
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #401769 0%, #0A061F 35%, #020414 60%, #1B4707 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #401769 0%, #0A061F 35%, #020414 60%, #1B4707 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #401769 0%, #0A061F 35%, #020414 60%, #1B4707 100%);
  /* IE10+ */
  background: linear-gradient(to top, #401769 0%, #0A061F 35%, #020414 60%, #1B4707 100%);
  /* W3C */
  min-height: 470px;
}

.chatbot-robot_grid {
  display: flex;
}

.chatbot-robot_grid [class*=cta-] {
  margin-right: 15px;
}

.chatbot-robot_grid_info {
  padding: 30px 0px 30px 0;
}

.chatbot-robot_grid_image {
  -ms-flex: 31%;
  flex: 0 0 31%;
  max-width: 31%;
  text-align: center;
}

.chatbot-robot_grid_image img {
  margin-top: -50px;
}

.pp-text-grid {
  margin: 40px 0 50px;
}

.pp-text-grid .banner-home_text {
  margin-bottom: 50px;
}

.pp-text-grid .banner-home_text.with-h1-line h1:after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -8px;
}

.pp-text-grid .privacy-policy_block {
  margin-bottom: 50px;
}

.pp-text-grid .privacy-policy_block h3 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 25px;
}

.pp-text-grid .privacy-policy_block h4 {
  margin-bottom: 25px;
}

.pp-text-grid .privacy-policy_block p {
  font-size: 14px;
}

.pp-text-grid .privacy-policy_block ul {
  margin-bottom: 25px;
}

.pp-text-grid .privacy-policy_block ul li {
  list-style-type: disc;
  margin-left: 18px;
  padding: 4px 0;
}


.affiliate-grid {
  margin: 40px 0 50px;
}

.affiliate-grid .banner-home_text {
  margin-bottom: 80px;
}

.affiliate-grid .banner-home_text.with-h1-line h1 {
  font-size: 64px;
}

.affiliate-grid .banner-home_text p {
  margin-top: 30px;
}

.affiliate-program {
  margin: 50px 0;
}

.affiliate-program figure {
  text-align: center;
}

.affiliate-program article {
  padding: 25px 0 0 0px;
  text-align: center;
}

.affiliate-program article h4 {
  font-weight: 600;
  font-size: 28px;
}

.affiliate-program article h5 {
  font-size: 24px;
}

.affiliate-benefits {
  padding: 50px 0;
}

.affiliate-benefits header {
  text-align: center;
  margin-bottom: 50px;
}

.affiliate-benefits header h4 {
  font-size: 38px;
  font-weight: 600;
}

.affiliate-benefits .affiliate_cards {
  gap: 50px 5.333%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.affiliate-benefits .affiliate_cards .affiliate_card {
  width: 28%;
}

.affiliate-benefits .affiliate_cards .affiliate_card figure {
  margin-bottom: 30px;
}

.affiliate-benefits .affiliate_cards .affiliate_card h4 {
  font-size: 24px;
}

.affiliate-benefits .affiliate_cards .affiliate_card p {
  font-size: 14px;
}

.affiliate-join_block {
  text-align: center;
  padding: 50px 0;
}

.affiliate-join_block h4 {
  font-size: 38px;
  font-weight: 600;
  margin-bottom: 30px;
}

.affiliate-join_block p {
  font-size: 20px;
}

@media screen and (min-width: 1024px) {
  .affiliate-program {
    display: flex;
    margin: 50px 0;
  }

  .affiliate-program figure {
    display: flex;
    align-items: center;
    justify-content: center;
    -ms-flex: 610px;
    flex: 0 0 610px;
    max-width: 610px;
  }

  .affiliate-program article {
    flex: 1 1 auto;
    padding: 25px 0 0 60px;
    text-align: left;
  }
}


.search-select-filter-wrap{gap: 12px;}
.searchbox-filter .form-control{background-color: #1F2645; border-color: #1F2645;}
.searchbox-filter .form-control:focus{background-color: #1F2645; border-color: #1F2645 !important; box-shadow:inherit;}
.selecFilterBy{position: relative;}
.selecFilterBy span{position: absolute; top: -24px; left: 0; color: #7a7a7a;}
.selecFilterBy .form-select{padding: 9px 10px;}

.user-details-form .form-control{
  color: var(--color-text);
  background-color: var(--color-010214);
  border: 1px solid var(--color-white);
  color-scheme: dark;
  position: relative;
}
.user-details-form .form-control:disabled{opacity: 0.3;}

.plan-details-modal .table tbody th,
.plan-details-modal .table tbody td{background: #09071d;}


/* Tutorials Slider Css */
.slider-wrapper {position: relative; width: 100%;}
.swiper-container {margin-left: auto; margin-right: auto; position: relative; overflow: hidden; z-index: 1;}
.swiper-wrapper {
  width: 100%; height: 100%; z-index: 1; position: relative;
  display: flex; transition-property: transform; box-sizing: content-box;
}
.swiper-slide {
  -webkit-flex-shrink: 0; -ms-flex: 0 0 auto; flex-shrink: 0;
  width: 100%; height: 100%; position: relative;
}
.swiper-slide.swiper-slide {
  width: 20%; 
  /*transform: translateX(-150%);*/
}

.tutorials-videos-card{margin: 0px;}
.tutorials-videos-card img{width: 100%;}
.tutorials-videos-card .play-video{
  position: absolute; top: 50%; left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer; font-size: 72px;
}

/* .swiper-button-next, 
.swiper-button-prev {
  position: absolute; top: 0; width: 50px; height: 50px;
  z-index: 10; cursor: pointer; font-size: 24px; background-color: #00282D;
  display: flex; align-items: center; justify-content: center;
}

.swiper-button-prev {right: 70px;}
.swiper-button-next {right: 0px;} */

.documentation-grid_list .tutorials-video-grid .slider-wrapper{
  position: relative; overflow: hidden; 

}
.documentation-grid_list .tutorials-video-grid .swiper-slide.swiper-slide{width: 100%;}

/* End Css */

.footer-gbl {
  position: relative;
  padding: 30px 0 0;
  background-color: #040b28;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#040b28), to(#010214));
  background-image: -webkit-linear-gradient(top, #040b28, #010214);
  background-image: -moz-linear-gradient(top, #040b28, #010214);
  background-image: -ms-linear-gradient(top, #040b28, #010214);
  background-image: -o-linear-gradient(top, #040b28, #010214);
  background-image: linear-gradient(top, #040b28, #010214);
}

.footer-gbl .footer-block {
  display: flex;
  justify-content: space-between; 
  font-size: 14px;
}

.footer-gbl .footer-block_logo {
  max-width: 370px;
  width: 100%;
  padding-right: 3%;
}

.footer-gbl .footer-block_link {
  max-width: 235px;
  width: 100%;
}

.footer-gbl .footer-block_link ul li {
  margin: 0 0 22px;
  text-transform: uppercase;
}

.footer-gbl .footer-block_visitus {
  max-width: 240px;
  width: 100%;
  padding-left: 2%;
}

.footer-gbl .footer-block_visitus [class*=cta-] {
  font-weight: 700;
}

.footer-gbl .footer-block_visitus p {
  margin-bottom: 10px;
}

.footer-gbl .footer-block_contact {
  max-width: 320px;
  width: 100%;
  padding-left: 2%;
}

.footer-gbl .footer-block_contact ul li {
  margin: 0 0 10px;
  text-transform: uppercase;
}

.footer-gbl .footer-block_contact ul li span {
  color: var(--color-primary);
}

.footer-gbl .footer-bottom {
  border-top: 1px solid var(--color-262626);
  padding: 15px 0;
}

.footer-gbl .footer-bottom .small-txt {
  font-size: 12px;
  color: var(--color-7A7979);
  text-align: center;
}

.footer-gbl .footer-bottom .small-txt p {
  font-size: 12px;
  color: #ffff99;
}

@media screen and (min-width: 768px) {
  .footer-gbl .footer-bottom {
    padding: 15px 0;
  }

  .footer-gbl .footer-bottom .small-txt {
    text-align: left;
  }

  .footer-gbl .footer-bottom .small-txt p {
    margin: 0;
  }

  .footer-gbl .footer-bottom .social-media ul {
    justify-content: end;
  }
}

@media screen and (max-width: 1400px) {
  .contact-grid_block .contact-block .contact-left {margin-bottom: 0px;}
  .contact-grid_block .contact-block iframe {height: 930px !important;}
}

@media screen and (max-width: 1200px) {
  .feature-list_block figure{width: 140px; height: 140px; padding: 34px;}
  .feature-list_block h5 {font-size: 18px;}

  .swiper-slide.swiper-slide {width: 25%;}
}

@media screen and (max-width: 1024px) {
  h3 {font-size: 40px; line-height: 120%;}
  .gbl-100 {
    margin-top: 60px;
    margin-bottom:60px;
  }

  .banner-home_text h1{font-size: 56px; line-height: 65px;}  
  .pricing-grid .banner-home_text h2{font-size: 56px; line-height: 65px;}
  .banner-home_text h2{font-size: 36px; line-height: 50px;}
  .affiliate-grid .banner-home_text.with-h1-line h1 {font-size: 56px; line-height: 65px;}

  .banner-home_text.with-h1-line h1:after{width: 300px; transform: translateX(50%); right: 50%;}
  .banner-home_text.with-h1-line h1:after

  .subscriber_block{padding: 20px}

  .feature-list_grid {flex-wrap: wrap;}
  .feature-list_connector.two{display: block; width: 100%;}
  .feature-list_connector.two img{display: none;}

  .chatbot-features .custom-ai-solutions figure:after,
  .chatbot-features .seamless-integration article .button-grid:after,
  .chatbot-features .subscriber-dashboard article .button-grid:after,
  .chatbot-features .industry-specific article .button-grid:after {
    display: none;
  }

  .chatbot-features .industry-specific figure img{margin-left: 0px;}

  .contact-grid_block .contact-info ul {
    flex-wrap: wrap; gap: 30px 0;
    justify-content: flex-start;    
  }

  .contact-grid_block .contact-info ul li {width: 50%; font-size: 20px;}
  .contact-grid_block .contact-info ul li:last-child {max-width: 100%;}
  
  .swiper-button-prev {right: 80px;}
  .swiper-button-next {right: 10px;}

  .footer-gbl .footer-block {
    flex-wrap: wrap; gap: 30px 0;
  }
}

@media screen and (max-width: 992px){
  .affiliate-benefits .affiliate_cards {gap: 50px 2%;}  
  .affiliate-benefits .affiliate_cards .affiliate_card {width: 48%;}

  .contact-grid_block .contact-info ul {padding: 0;}

  .contact-grid_block .contact-block {flex-wrap: wrap;}
  .contact-grid_block .contact-block .contact-left {width: 100%; margin-bottom: 20px;}
  .contact-grid_block .contact-block .contact-left .contact-form {padding:30px 20px; height: auto;}
  .contact-grid_block .contact-block .contact-right {width: 100%; margin-bottom: 0px;}
  .contact-grid_block .contact-block iframe {height: 1015px !important; margin-top: 0 !important;}
  .contact-grid_block .contact-block .or-text {display: block; text-align: center; width: 100%;}

  .documentation-grid_list #ChatbotIntegration, 
  .documentation-grid_list #FAQs, 
  .documentation-grid_list #tutorialsVideo {
    padding-top: 70px;
  }
}

@media screen and (max-width: 768px) {
  h3 {font-size: 30px; line-height: 120%;}

  .banner-home_text h1{font-size: 40px; line-height: 50px;}
  .banner-home_text.with-h1-line h1:after{width: 200px;}
  .pricing-grid .banner-home_text h2{font-size: 40px; line-height: 50px;}
  .banner-home_text h2{font-size: 26px; line-height: 36px;}
  .affiliate-grid .banner-home_text.with-h1-line h1 {font-size: 40px; line-height: 50px;}

  
  .group-img-grid{overflow: hidden;}
  .group-img-grid .ctaBlock {
    background-color: rgba(0, 0, 0, 0.60); position: relative; top: 0;
    min-height: 200px; text-align: center; padding: 30px 0;
  }
  .group-img-grid_block .img-list {
    display: flex; flex-wrap: wrap; align-items: center;
    margin: 0px; position: absolute; top: 0; left: 0; z-index: 0;
  }
  .group-img-grid_block {min-height: auto;}
  .group-img-grid .ctaBlock.with-h2-line header h2{font-size: 30px;}
  .group-img-grid .ctaBlock.with-h2-line header h2:first-child:after {width: 90px; bottom: -2px; background-size: 100%;}
  .group-img-grid .ctaBlock.with-h2-line header h5 {font-size: 18px;}

  .subscriber_block{padding: 15px}
  .subscriber_block .counter-item .counter {font-size: 26px; line-height: normal;}
  .subscriber_block .subscriber-text {font-size: 18px; flex: 0 0 150px; max-width: 150px;}
  .subscriber_block .subscriber-text img {margin-right: 0px; width: 25px;}

  .feature-list_connector.one,
  .feature-list_connector.two,
  .feature-list_connector.three{display: none;}  

  .chatbot-features .custom-ai-solutions,
  .chatbot-features .seamless-integration,
  .chatbot-features .subscriber-dashboard,
  .chatbot-features .industry-specific {flex-wrap: wrap; flex-direction: column; margin-bottom: 0;}

  .chatbot-features .custom-ai-solutions figure,
  .chatbot-features .seamless-integration figure,
  .chatbot-features .subscriber-dashboard figure,
  .chatbot-features .industry-specific figure {
    width: 100%; margin-bottom: 30px; text-align: center;
    flex: 0 0 100%; max-width: 100%; -ms-flex: 100%; padding: 0;
  }
  .chatbot-features .custom-ai-solutions article,
  .chatbot-features .seamless-integration article,
  .chatbot-features .subscriber-dashboard article,
  .chatbot-features .industry-specific article {
    flex: 0 0 100%; max-width: 100%; -ms-flex: 100%; text-align: center; padding-top: 0;
  }

  .chatbot-features .industry-specific article{padding: 0;}
  .chatbot-features .seamless-integration article h3,
  .chatbot-features .industry-specific article h3,
  .chatbot-features .industry-specific article p,
  .chatbot-features .seamless-integration article p{max-width: 100%;}

  .freetrial-grid_gredient {padding: 60px 0px 0px;}

  .chatbot-robot{margin-top: 0;}
  .chatbot-robot.gradient{min-height: 300px;}
  .chatbot-robot_grid_info{padding: 50px 0px 50px 0;}
  .chatbot-robot_grid_image{display: none;}

  .documentation-grid_list #ChatbotIntegration, 
  .documentation-grid_list #FAQs, 
  .documentation-grid_list #tutorialsVideo {
    padding-top: 50px;
  }
  .documentation-grid_list .group-link ul {
    flex-wrap: wrap;    
    gap: 15px 10px;
  }
  .documentation-grid_list .group-link ul li .cta-border{
    padding: 15px;
  }

  .contact-grid_block .contact-info ul {
    padding: 0; align-items: flex-start; gap: 20px 0;
  }
  .contact-grid_block .contact-info ul li {
    font-size: 16px; padding: 0 10px;
    display: flex; align-items: center;
  }
  .contact-grid_block .contact-info ul li img {width: 24px;}

  .contact-grid_block .contact-block iframe {height: 915px !important; margin-top: 0 !important;}

  .affiliate-benefits .affiliate_cards {gap: 30px 0;}    
  .affiliate-benefits .affiliate_cards .affiliate_card {width: 100%;}
  
  .swiper-slide.swiper-slide {width: 50%;}  

  .footer-gbl .footer-block {justify-content: flex-start; gap: 20px 0;}
  .footer-gbl .footer-block_logo {max-width: 100%; padding: 0;}
  .footer-gbl .footer-block_link ul li {margin-bottom:10px;}
  .footer-gbl .footer-block_visitus {padding-left: 0;}
}

@media screen and (max-width: 580px){
  .gbl-header .group-user-cta .cta-border{font-size: 12px;}
  .freetrial-grid.upper {margin-top:0px;}
  .freetrial-grid_gredient {padding-top: 20px;}

  .feature-list_block {margin: 10px 0; max-width: 100%;}
  .feature-list header h2{font-size: 30px;}
  .feature-list_block figure {width: 120px; height: 120px; padding: 24px;}

  .chatbot-robot_grid [class*=cta-] {
    margin-bottom: 10px;
  }
}
/* sourceMappingURL=coolbiz-chatbot_gbl.css.map */

/* Custom Alert modal  */
.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal Container */
.custom-modal {
  background:#040b28;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  width: 400px;
  max-width: 90%;
  text-align: center;
  position: relative;
}

/* Modal Header */
.custom-modal h2 {
  margin-bottom: 10px;
  font-size: 1.5em;
}

/* Modal Body */
.custom-modal p {
  margin-bottom: 20px;
  font-size: 1em;
  color: #e6dede;
}

/* Modal Actions */
.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.btn-danger {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-danger:hover {
  background-color: #c0392b;
}

.btn-secondary {
  background-color: #95a5a6;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-secondary:hover {
  background-color: #7f8c8d;
}



